const VideoPlayer = (props) => {
    const { video, houseId } = props;
    return (
        <div>
            <iframe title={houseId} src={video} />
        </div>
    );
};

export default VideoPlayer;
