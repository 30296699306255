export const shops = [
    {
        id: "1",
        brandName: "BIM",
        coords: [36.46345479759451, 34.14324851002797],
    },
    {
        id: "2",
        brandName: "BIM",
        coords: [36.49752002580352, 34.183088955168515],
    },
    {
        id: "3",
        brandName: "BIM",
        coords: [36.557295265552405, 34.243630152801785],
    },
    {
        id: "4",
        brandName: "BIM",
        coords: [36.60633981047802, 34.31176264500028],
    },
    {
        id: "5",
        brandName: "BIM",
        coords: [36.60916468795346, 34.310818507411355],
    },
    {
        id: "6",
        brandName: "BIM",
        coords: [36.61116270954791, 34.315968348805484],
    },
    {
        id: "7",
        brandName: "BIM",
        coords: [36.62453043237469, 34.32272290412497],
    },
    {
        id: "8",
        brandName: "BIM",
        coords: [36.62542826010054, 34.33683306158836],
    },
    {
        id: "9",
        brandName: "BIM",
        coords: [36.64011060074634, 34.35985679684772],
    },
    {
        id: "10",
        brandName: "BIM",
        coords: [36.65062109046693, 34.38309705604371],
    },
    {
        id: "11",
        brandName: "BIM",
        coords: [36.657337787029896, 34.392371507494],
    },
    {
        id: "12",
        brandName: "BIM",
        coords: [36.6572798869771, 34.39226324541481],
    },
    {
        id: "13",
        brandName: "BIM",
        coords: [36.66457495107651, 34.41846266801446],
    },
    {
        id: "14",
        brandName: "BIM",
        coords: [36.66492231781907, 34.419653550885506],
    },
    {
        id: "15",
        brandName: "BIM",
        coords: [36.673490201179284, 34.40767254745556],
    },
    {
        id: "16",
        brandName: "BIM",
        coords: [36.676529254912225, 34.41102867191033],
    },
    {
        id: "17",
        brandName: "BIM",
        coords: [36.678034265648115, 34.41203911798274],
    },
    {
        id: "18",
        brandName: "BIM",
        coords: [36.69528190160395, 34.41438479595574],
    },
    {
        id: "19",
        brandName: "BIM",
        coords: [36.690015416395916, 34.429613661761266],
    },
    {
        id: "20",
        brandName: "BIM",
        coords: [36.6926776408173, 34.44141422839257],
    },
    {
        id: "21",
        brandName: "BIM",
        coords: [36.699824679228286, 34.45310653244487],
    },
    {
        id: "22",
        brandName: "BIM",
        coords: [36.706508149815136, 34.46317490580919],
    },
    {
        id: "23",
        brandName: "BIM",
        coords: [36.71819559303655, 34.47746550026177],
    },
    {
        id: "24",
        brandName: "BIM",
        coords: [36.71967086171396, 34.485657330920205],
    },
    {
        id: "25",
        brandName: "BIM",
        coords: [36.72678646612852, 34.50178837999315],
    },
    {
        id: "26",
        brandName: "BIM",
        coords: [36.73124061434042, 34.49940661425105],
    },
    {
        id: "27",
        brandName: "BIM",
        coords: [36.72889788028217, 34.505505378045214],
    },
    {
        id: "28",
        brandName: "BIM",
        coords: [36.73031509831543, 34.50521667916738],
    },
    {
        id: "29",
        brandName: "BIM",
        coords: [36.73309161292211, 34.50319578702258],
    },
    {
        id: "30",
        brandName: "BIM",
        coords: [36.73656211498376, 34.499478788970514],
    },
    {
        id: "31",
        brandName: "BIM",
        coords: [36.7317322901911, 34.511135006162895],
    },
    {
        id: "32",
        brandName: "BIM",
        coords: [36.73248425893146, 34.5126506752715],
    },
    {
        id: "33",
        brandName: "BIM",
        coords: [36.73364111953558, 34.50803149322623],
    },
    {
        id: "34",
        brandName: "BIM",
        coords: [36.740061379038856, 34.50651582411761],
    },
    {
        id: "35",
        brandName: "BIM",
        coords: [36.73618615150459, 34.51463548005658],
    },
    {
        id: "36",
        brandName: "BIM",
        coords: [36.73789243250996, 34.52387384414715],
    },
    {
        id: "37",
        brandName: "BIM",
        coords: [36.74309780121591, 34.52437906718335],
    },
    {
        id: "38",
        brandName: "BIM",
        coords: [36.74651001801911, 34.518316390748915],
    },
    {
        id: "39",
        brandName: "BIM",
        coords: [36.74084218478613, 34.52167251520369],
    },
    {
        id: "40",
        brandName: "BIM",
        coords: [36.748418479899605, 34.5234407958304],
    },
    {
        id: "41",
        brandName: "BIM",
        coords: [36.751425656840354, 34.52181686464261],
    },
    {
        id: "42",
        brandName: "BIM",
        coords: [36.74948835443304, 34.52636387196843],
    },
    {
        id: "43",
        brandName: "BIM",
        coords: [36.74827390157015, 34.53964401967428],
    },
    {
        id: "44",
        brandName: "BIM",
        coords: [36.748389564618954, 34.535313536506834],
    },
    {
        id: "45",
        brandName: "BIM",
        coords: [36.74902570827108, 34.532967858124465],
    },
    {
        id: "46",
        brandName: "BIM",
        coords: [36.75347856616351, 34.54310840620824],
    },
    {
        id: "47",
        brandName: "BIM",
        coords: [36.755184462729076, 34.5347722261109],
    },
    {
        id: "48",
        brandName: "BIM",
        coords: [36.759347847613185, 34.53632398257924],
    },
    {
        id: "49",
        brandName: "BIM",
        coords: [36.75856723016012, 34.54155664973991],
    },
    {
        id: "50",
        brandName: "BIM",
        coords: [36.762036580252904, 34.54902673320376],
    },
    {
        id: "51",
        brandName: "BIM",
        coords: [36.76489867593948, 34.5431805809277],
    },
    {
        id: "52",
        brandName: "BIM",
        coords: [36.771056156039236, 34.54115968878289],
    },
    {
        id: "53",
        brandName: "BIM",
        coords: [36.77062254686485, 34.55566680739384],
    },
    {
        id: "54",
        brandName: "BIM",
        coords: [36.780912876782054, 34.56158513389606],
    },
    {
        id: "55",
        brandName: "BIM",
        coords: [36.783543048309795, 34.55804857264264],
    },
    {
        id: "56",
        brandName: "BIM",
        coords: [36.78603267851921, 34.563494221361324],
    },
    {
        id: "57",
        brandName: "BIM",
        coords: [36.7832830349842, 34.56997443844894],
    },
    {
        id: "58",
        brandName: "BIM",
        coords: [36.7822175215918, 34.59220458713361],
    },
    {
        id: "59",
        brandName: "BIM",
        coords: [36.78603267851921, 34.5821194810701],
    },
    {
        id: "60",
        brandName: "BIM",
        coords: [36.786204527964294, 34.59482242317562],
    },
    {
        id: "61",
        brandName: "BIM",
        coords: [36.79177224144856, 34.59035922730071],
    },
    {
        id: "62",
        brandName: "BIM",
        coords: [36.793250023375066, 34.593277470757386],
    },
    {
        id: "63",
        brandName: "BIM",
        coords: [36.79421228465378, 34.60147430164304],
    },
    {
        id: "64",
        brandName: "BIM",
        coords: [36.78716687806282, 34.610572353957195],
    },
    {
        id: "65",
        brandName: "BIM",
        coords: [36.801085228309084, 34.60722495705101],
    },
    {
        id: "66",
        brandName: "BIM",
        coords: [36.79060374286358, 34.61718131707966],
    },
    {
        id: "67",
        brandName: "BIM",
        coords: [36.7924595857537, 34.61962749174187],
    },
    {
        id: "68",
        brandName: "BIM",
        coords: [36.80136013321439, 34.606881634291405],
    },
    {
        id: "69",
        brandName: "BIM",
        coords: [36.798817225189026, 34.624906079170856],
    },
    {
        id: "70",
        brandName: "BIM",
        coords: [36.802459742967756, 34.62730933848812],
    },
    {
        id: "71",
        brandName: "BIM",
        coords: [36.80338752641629, 34.63155795763827],
    },
    {
        id: "72",
        brandName: "BIM",
        coords: [36.80582719962035, 34.626150624174436],
    },
    {
        id: "73",
        brandName: "BIM",
        coords: [36.80953810277635, 34.62816764538714],
    },
    {
        id: "74",
        brandName: "BIM",
        coords: [36.801257043990546, 34.607096211016156],
    },
    {
        id: "75",
        brandName: "BIM",
        coords: [36.80826679509673, 34.60941363964351],
    },
    {
        id: "76",
        brandName: "BIM",
        coords: [36.81019092824642, 34.60619498877219],
    },
    {
        id: "77",
        brandName: "BIM",
        coords: [36.81266474267008, 34.61022903119758],
    },
    {
        id: "78",
        brandName: "BIM",
        coords: [36.81771519890501, 34.60941363964351],
    },
    {
        id: "79",
        brandName: "BIM",
        coords: [36.81208065480667, 34.62173034364448],
    },
    {
        id: "80",
        brandName: "BIM",
        coords: [36.81517283401598, 34.62889720625131],
    },
    {
        id: "81",
        brandName: "BIM",
        coords: [36.823692859295235, 34.63069965073926],
    },
    {
        id: "82",
        brandName: "BIM",
        coords: [36.81685630129314, 34.64520503733273],
    },
    {
        id: "83",
        brandName: "BIM",
        coords: [36.81520719122477, 34.640999332991484],
    },
    {
        id: "84",
        brandName: "BIM",
        coords: [36.81565382880834, 34.643402592308746],
    },
    {
        id: "85",
        brandName: "BIM",
        coords: [36.81695936987911, 34.64499046007194],
    },
    {
        id: "86",
        brandName: "BIM",
        coords: [36.82121940164234, 34.65172816922926],
    },
    {
        id: "87",
        brandName: "BIM",
        coords: [36.820223125134056, 34.64473296800223],
    },
    {
        id: "88",
        brandName: "BIM",
        coords: [36.825341787254814, 34.65215732267877],
    },
    {
        id: "89",
        brandName: "BIM",
        coords: [36.83465069100618, 34.639754787987904],
    },
    {
        id: "90",
        brandName: "BIM",
        coords: [36.84055833751748, 34.64396049179311],
    },
    {
        id: "91",
        brandName: "BIM",
        coords: [36.82403638917153, 34.673142926359844],
    },
    {
        id: "92",
        brandName: "BIM",
        coords: [36.90749444978095, 34.88205748737526],
    },
    {
        id: "93",
        brandName: "BIM",
        coords: [36.90846502886858, 34.8742889801287],
    },
    {
        id: "94",
        brandName: "BIM",
        coords: [36.91399709387534, 34.88521344344418],
    },
    {
        id: "95",
        brandName: "BIM",
        coords: [36.917490822949944, 34.88472791174127],
    },
    {
        id: "96",
        brandName: "BIM",
        coords: [36.91778195981522, 34.900507692085846],
    },
    {
        id: "97",
        brandName: "BIM",
        coords: [36.91894649616182, 34.89565237505675],
    },
    {
        id: "98",
        brandName: "BIM",
        coords: [36.921857759225915, 34.883635465409725],
    },
    {
        id: "99",
        brandName: "BIM",
        coords: [36.92127551550491, 34.86227207048169],
    },
    {
        id: "100",
        brandName: "BIM",
        coords: [36.92583630566038, 34.856688455898215],
    },
    {
        id: "101",
        brandName: "BIM",
        coords: [36.92797104986688, 34.864335580219056],
    },
    {
        id: "102",
        brandName: "BIM",
        coords: [36.92787401733612, 34.87841599960343],
    },
    {
        id: "103",
        brandName: "BIM",
        coords: [36.93359872532758, 34.86737015336224],
    },
    {
        id: "104",
        brandName: "BIM",
        coords: [36.94009914355945, 34.85850919978414],
    },
    {
        id: "105",
        brandName: "BIM",
        coords: [36.94106930768093, 34.876838021568986],
    },
    {
        id: "106",
        brandName: "BIM",
        coords: [36.93942002132487, 34.87635248986607],
    },
    {
        id: "107",
        brandName: "BIM",
        coords: [36.93844983620479, 34.88108642396945],
    },
    {
        id: "108",
        brandName: "BIM",
        coords: [36.93000870448268, 34.89358886531938],
    },
    {
        id: "109",
        brandName: "BIM",
        coords: [36.92991167454559, 34.90147875549167],
    },
    {
        id: "110",
        brandName: "BIM",
        coords: [36.93088196835869, 34.92963959426044],
    },
    {
        id: "111",
        brandName: "A-101",
        coords: [36.41221324170475, 34.07631226960943],
    },
    {
        id: "112",
        brandName: "A-101",
        coords: [36.416813111807535, 34.07845574920769],
    },
    {
        id: "113",
        brandName: "A-101",
        coords: [36.416813111807535, 34.08080336972006],
    },
    {
        id: "114",
        brandName: "А-101",
        coords: [36.42100204220552, 34.084273765260086],
    },
    {
        id: "115",
        brandName: "A-101",
        coords: [36.421610504813344, 34.09063440427271],
    },
    {
        id: "116",
        brandName: "A-101",
        coords: [36.46055360373329, 34.14143144456522],
    },
    {
        id: "117",
        brandName: "A-101",
        coords: [36.46269352650124, 34.14048730706933],
    },
    {
        id: "118",
        brandName: "А-101",
        coords: [36.463659923877, 34.141860597972446],
    },
    {
        id: "119",
        brandName: "A-101",
        coords: [36.46669709435453, 34.146323793407575],
    },
    {
        id: "120",
        brandName: "A-101",
        coords: [36.49489213621474, 34.18153202502217],
    },
    {
        id: "121",
        brandName: "A-101",
        coords: [36.497824586541164, 34.18402111478407],
    },
    {
        id: "122",
        brandName: "А-101",
        coords: [36.613839303396674, 34.29921074299368],
    },
    {
        id: "123",
        brandName: "A-101",
        coords: [36.61179104494668, 34.30038455324986],
    },
    {
        id: "124",
        brandName: "A-101",
        coords: [36.61273325059084, 34.30280872660503],
    },
    {
        id: "125",
        brandName: "A-101",
        coords: [36.611688630596056, 34.30553911133138],
    },
    {
        id: "126",
        brandName: "А-101",
        coords: [36.60925112891124, 34.30395701924695],
    },
    {
        id: "127",
        brandName: "A-101",
        coords: [36.60933306299228, 34.307325344329925],
    },
    {
        id: "128",
        brandName: "A-101",
        coords: [36.60947644742463, 34.31533787520911],
    },
    {
        id: "129",
        brandName: "A-101",
        coords: [36.60875952259708, 34.31133160976951],
    },
    {
        id: "130",
        brandName: "А-101",
        coords: [36.60488801336894, 34.29803693273749],
    },
    {
        id: "131",
        brandName: "A-101",
        coords: [36.60632192833409, 34.30793776707228],
    },
    {
        id: "132",
        brandName: "A-101",
        coords: [36.603617952138904, 34.30000178903589],
    },
    {
        id: "133",
        brandName: "A-101",
        coords: [36.603617952138904, 34.30000178903589],
    },
    {
        id: "134",
        brandName: "А-101",
        coords: [36.60470365089017, 34.30768259113656],
    },
    {
        id: "135",
        brandName: "A-101",
        coords: [36.605564005124435, 34.31105091621953],
    },
    {
        id: "136",
        brandName: "A-101",
        coords: [36.60691597096101, 34.312684043532485],
    },
    {
        id: "137",
        brandName: "A-101",
        coords: [36.611606698968316, 34.32120692669697],
    },
    {
        id: "138",
        brandName: "А-101",
        coords: [36.6373494134809, 34.35652330903125],
    },
    {
        id: "139",
        brandName: "A-101",
        coords: [36.64197686539011, 34.36366824102543],
    },
    {
        id: "140",
        brandName: "A-101",
        coords: [36.64334866718465, 34.36739381270811],
    },
    {
        id: "141",
        brandName: "A-101",
        coords: [36.64789386570943, 34.367980717836204],
    },
    {
        id: "142",
        brandName: "А-101",
        coords: [36.648098598068444, 34.36810830590753],
    },
    {
        id: "143",
        brandName: "A-101",
        coords: [36.64809859824676, 34.372829066892216],
    },
    {
        id: "144",
        brandName: "A-101",
        coords: [36.64965454638888, 34.38344439442642],
    },
    {
        id: "145",
        brandName: "A-101",
        coords: [36.64983880132391, 34.382959559755385],
    },
    {
        id: "146",
        brandName: "А-101",
        coords: [36.65589849572207, 34.39648389531579],
    },
    {
        id: "147",
        brandName: "A-101",
        coords: [36.67522082191946, 34.40988064425781],
    },
    {
        id: "148",
        brandName: "A-101",
        coords: [36.685043873151315, 34.42077666663604],
    },
    {
        id: "149",
        brandName: "A-101",
        coords: [36.663308690317294, 34.41863318703779],
    },
    {
        id: "150",
        brandName: "А-101",
        coords: [36.67346072631836, 34.431213370870395],
    },
    {
        id: "151",
        brandName: "A-101",
        coords: [36.679702744951626, 34.43483687386884],
    },
    {
        id: "152",
        brandName: "A-101",
        coords: [36.6893819881788, 34.44435494398965],
    },
    {
        id: "153",
        brandName: "A-101",
        coords: [36.69247172263056, 34.44167559449184],
    },
    {
        id: "154",
        brandName: "А-101",
        coords: [36.69934644806526, 34.451984711716946],
    },
    {
        id: "155",
        brandName: "A-101",
        coords: [36.705033999999635, 34.46277866255101],
    },
    {
        id: "156",
        brandName: "A-101",
        coords: [36.719619214851, 34.47375123819436],
    },
    {
        id: "157",
        brandName: "A-101",
        coords: [36.718801043301596, 34.48416242481444],
    },
    {
        id: "158",
        brandName: "А-101",
        coords: [36.71955785254131, 34.48686729364918],
    },
    {
        id: "159",
        brandName: "A-101",
        coords: [36.73152262526056, 34.49919230133914],
    },
    {
        id: "160",
        brandName: "A-101",
        coords: [36.73559226832386, 34.50373443667829],
    },
    {
        id: "161",
        brandName: "А-101",
        coords: [36.73661475779606, 34.499268854181935],
    },
    {
        id: "162",
        brandName: "A-101",
        coords: [36.729497948833966, 34.506133094037224],
    },
    {
        id: "163",
        brandName: "A-101",
        coords: [36.73301553467691, 34.513431131716985],
    },
    {
        id: "164",
        brandName: "А-101",
        coords: [36.734508414839205, 34.510573158919314],
    },
    {
        id: "165",
        brandName: "A-101",
        coords: [36.73649206000341, 34.51511529425847],
    },
    {
        id: "166",
        brandName: "A-101",
        coords: [36.738884635972155, 34.5093483134346],
    },
    {
        id: "167",
        brandName: "А-101",
        coords: [36.7396821447232, 34.50651585825119],
    },
    {
        id: "168",
        brandName: "A-101",
        coords: [36.742953888767616, 34.51233387430359],
    },
    {
        id: "169",
        brandName: "A-101",
        coords: [36.7404796451882, 34.519198112540856],
    },
    {
        id: "170",
        brandName: "А-101",
        coords: [36.741174895243105, 34.52078020462528],
    },
    {
        id: "171",
        brandName: "A-101",
        coords: [36.742054173058364, 34.524556811536485],
    },
    {
        id: "172",
        brandName: "A-101",
        coords: [36.755548801466226, 34.53514662315183],
    },
    {
        id: "173",
        brandName: "А-101",
        coords: [36.75601902601578, 34.533743154367265],
    },
    {
        id: "174",
        brandName: "A-101",
        coords: [36.75655058073142, 34.53644802147935],
    },
    {
        id: "175",
        brandName: "A-101",
        coords: [36.75704124335356, 34.53517214076611],
    },
    {
        id: "176",
        brandName: "А-101",
        coords: [36.741011307833936, 34.5278996207006],
    },
    {
        id: "177",
        brandName: "A-101",
        coords: [36.742831201155326, 34.52761892694368],
    },
    {
        id: "178",
        brandName: "A-101",
        coords: [36.7438665960219, 34.52968531030349],
    },
    {
        id: "179",
        brandName: "А-101",
        coords: [36.745276525142806, 34.528312019400374],
    },
    {
        id: "180",
        brandName: "A-101",
        coords: [36.74575795842216, 34.53228168841719],
    },
    {
        id: "181",
        brandName: "A-101",
        coords: [36.746153419213655, 34.53758173299641],
    },
    {
        id: "182",
        brandName: "А-101",
        coords: [36.74801033828682, 34.53541450828993],
    },
    {
        id: "183",
        brandName: "A-101",
        coords: [36.74766646777454, 34.54189472473901],
    },
    {
        id: "184",
        brandName: "A-101",
        coords: [36.74919667972513, 34.54487734091922],
    },
    {
        id: "185",
        brandName: "А-101",
        coords: [36.749867212424654, 34.54230242047588],
    },
    {
        id: "186",
        brandName: "A-101",
        coords: [36.75208508653046, 34.5364230187969],
    },
    {
        id: "187",
        brandName: "A-101",
        coords: [36.75261805447425, 34.53992061906578],
    },
    {
        id: "188",
        brandName: "А-101",
        coords: [36.753701171780975, 34.54236679443799],
    },
    {
        id: "189",
        brandName: "A-101",
        coords: [36.76019955262036, 34.54740934697287],
    },
    {
        id: "190",
        brandName: "A-101",
        coords: [36.75947753749074, 34.54116516489776],
    },
    {
        id: "191",
        brandName: "А-101",
        coords: [36.76372357653794, 34.546250632773365],
    },
    {
        id: "192",
        brandName: "А-101",
        coords: [36.76456588022839, 34.549340537305376],
    },
    {
        id: "193",
        brandName: "A-101",
        coords: [36.76709273579105, 34.549512198668275],
    },
    {
        id: "194",
        brandName: "A-101",
        coords: [36.76791781345191, 34.54805307708371],
    },
    {
        id: "195",
        brandName: "А-101",
        coords: [36.76796938051094, 34.5482247384466],
    },
    {
        id: "196",
        brandName: "А-101",
        coords: [36.76991171447986, 34.555026819951095],
    },
    {
        id: "197",
        brandName: "A-101",
        coords: [36.77078832697063, 34.5408647575127],
    },
    {
        id: "198",
        brandName: "A-101",
        coords: [36.771149282141906, 34.54436235778158],
    },
    {
        id: "199",
        brandName: "А-101",
        coords: [36.773675920758706, 34.53934126291707],
    },
    {
        id: "200",
        brandName: "А-101",
        coords: [36.773229038357364, 34.55509119296218],
    },
    {
        id: "201",
        brandName: "A-101",
        coords: [36.769997657551095, 34.55562763574078],
    },
    {
        id: "202",
        brandName: "A-101",
        coords: [36.772043068812614, 34.557837775787974],
    },
    {
        id: "203",
        brandName: "А-101",
        coords: [36.771372729956695, 34.56275158230069],
    },
    {
        id: "204",
        brandName: "А-101",
        coords: [36.77647747052848, 34.55341749569357],
    },
    {
        id: "205",
        brandName: "A-101",
        coords: [36.779485155146624, 34.567279150746906],
    },
    {
        id: "206",
        brandName: "A-101",
        coords: [36.78163342904512, 34.55743008005111],
    },
    {
        id: "207",
        brandName: "А-101",
        coords: [36.78403942432306, 34.566699793647146],
    },
    {
        id: "208",
        brandName: "А-101",
        coords: [36.77800710786684, 34.57167797460714],
    },
    {
        id: "209",
        brandName: "A-101",
        coords: [36.77957108738608, 34.57047634506691],
    },
    {
        id: "210",
        brandName: "A-101",
        coords: [36.77962264660708, 34.58092623053281],
    },
    {
        id: "211",
        brandName: "А-101",
        coords: [36.78183966029898, 34.5786088021338],
    },
    {
        id: "212",
        brandName: "А-101",
        coords: [36.78409098066548, 34.57255773909194],
    },
    {
        id: "213",
        brandName: "A-101",
        coords: [36.78747642952826, 34.56961803825246],
    },
    {
        id: "214",
        brandName: "A-101",
        coords: [36.79323306765967, 34.58066873848848],
    },
    {
        id: "215",
        brandName: "А-101",
        coords: [36.793714199916415, 34.57212858568472],
    },
    {
        id: "216",
        brandName: "А-101",
        coords: [36.781599057344245, 34.58622627625997],
    },
    {
        id: "217",
        brandName: "A-101",
        coords: [36.78574076131203, 34.5932858498088],
    },
    {
        id: "218",
        brandName: "A-101",
        coords: [36.79065552205301, 34.59742718018852],
    },
    {
        id: "219",
        brandName: "А-101",
        coords: [36.791634999629586, 34.59088259072835],
    },
    {
        id: "220",
        brandName: "А-101",
        coords: [36.79400631453522, 34.58659105665611],
    },
    {
        id: "221",
        brandName: "A-101",
        coords: [36.79624009476805, 34.5914619478281],
    },
    {
        id: "222",
        brandName: "A-101",
        coords: [36.79723668339631, 34.594573310030476],
    },
    {
        id: "223",
        brandName: "А-101",
        coords: [36.783626973659736, 34.60143976590318],
    },
    {
        id: "224",
        brandName: "А-101",
        coords: [36.78347230397413, 34.608949950529606],
    },
    {
        id: "225",
        brandName: "A-101",
        coords: [36.78501898678251, 34.608456424111296],
    },
    {
        id: "226",
        brandName: "A-101",
        coords: [36.78766546051295, 34.61126737892861],
    },
    {
        id: "227",
        brandName: "А-101",
        coords: [36.78548298553774, 34.61525850561579],
    },
    {
        id: "228",
        brandName: "А-101",
        coords: [36.78727021374892, 34.60171871561788],
    },
    {
        id: "229",
        brandName: "A-101",
        coords: [36.78996816198853, 34.60768394797829],
    },
    {
        id: "230",
        brandName: "A-101",
        coords: [36.793078417541984, 34.60682564116384],
    },
    {
        id: "231",
        brandName: "А-101",
        coords: [36.7929409503504, 34.60356407526894],
    },
    {
        id: "232",
        brandName: "А-101",
        coords: [36.79197867310245, 34.61506538658254],
    },
    {
        id: "233",
        brandName: "A-101",
        coords: [36.802253794796115, 34.608735373825986],
    },
    {
        id: "234",
        brandName: "A-101",
        coords: [36.787802937241004, 34.61120300685799],
    },
    {
        id: "235",
        brandName: "А-101",
        coords: [36.7865656384445, 34.616181186381795],
    },
    {
        id: "236",
        brandName: "А-101",
        coords: [36.79122258971321, 34.61817674972538],
    },
    {
        id: "237",
        brandName: "A-101",
        coords: [36.79149753001651, 34.61738281592202],
    },
    {
        id: "238",
        brandName: "A-101",
        coords: [36.79682430363609, 34.622554114479065],
    },
    {
        id: "239",
        brandName: "А-101",
        coords: [36.79881745272433, 34.62118082357595],
    },
    {
        id: "240",
        brandName: "А-101",
        coords: [36.8035939265106, 34.622425368456895],
    },
    {
        id: "241",
        brandName: "A-101",
        coords: [36.803748555575794, 34.62467842384482],
    },
    {
        id: "242",
        brandName: "A-101",
        coords: [36.80558689942843, 34.61937837926561],
    },
    {
        id: "243",
        brandName: "А-101",
        coords: [36.80577588554707, 34.62302618322701],
    },
    {
        id: "244",
        brandName: "А-101",
        coords: [36.804985576856964, 34.62680273321058],
    },
    {
        id: "245",
        brandName: "А-101",
        coords: [36.80247715190787, 34.628283313922864],
    },
    {
        id: "246",
        brandName: "А-101",
        coords: [36.805604080142494, 34.630858234366215],
    },
    {
        id: "247",
        brandName: "А-101",
        coords: [36.807717261533966, 34.63062219999224],
    },
    {
        id: "248",
        brandName: "А-101",
        coords: [36.808129582810416, 34.6277683298342],
    },
    {
        id: "249",
        brandName: "А-101",
        coords: [36.81228703160704, 34.64160852721717],
    },
    {
        id: "250",
        brandName: "А-101",
        coords: [36.81998287008389, 34.64669399627409],
    },
    {
        id: "251",
        brandName: "А-101",
        coords: [36.822899906443546, 34.640496828466006],
    },
    {
        id: "252",
        brandName: "А-101",
        coords: [36.92205205687543, 34.853034352361234],
    },
    {
        id: "253",
        brandName: "А-101",
        coords: [36.92494882279837, 34.85206468301918],
    },
    {
        id: "254",
        brandName: "А-101",
        coords: [36.92201125656943, 34.85277917621859],
    },
    {
        id: "255",
        brandName: "А-101",
        coords: [36.92474482880049, 34.857066135415096],
    },
    {
        id: "256",
        brandName: "А-101",
        coords: [36.933434489466435, 34.86808974477754],
    },
    {
        id: "257",
        brandName: "А-101",
        coords: [36.93930862841235, 34.87171324600316],
    },
    {
        id: "258",
        brandName: "А-101",
        coords: [36.93824805348651, 34.87038633006138],
    },
    {
        id: "259",
        brandName: "А-101",
        coords: [36.9372690481461, 34.88166511556648],
    },
    {
        id: "260",
        brandName: "А-101",
        coords: [36.93665716342156, 34.888963153246245],
    },
    {
        id: "261",
        brandName: "А-101",
        coords: [36.92433683916725, 34.87084564711815],
    },
    {
        id: "262",
        brandName: "А-101",
        coords: [36.92033842522671, 34.8758981347426],
    },
    {
        id: "263",
        brandName: "А-101",
        coords: [36.91107598764535, 34.878705072311746],
    },
    {
        id: "264",
        brandName: "А-101",
        coords: [36.910382279643635, 34.88028716439617],
    },
    {
        id: "265",
        brandName: "А-101",
        coords: [36.91425880230419, 34.884982405420914],
    },
    {
        id: "266",
        brandName: "А-101",
        coords: [36.9100558266364, 34.89217837264363],
    },
    {
        id: "267",
        brandName: "А-101",
        coords: [36.923520853351135, 34.886768638419454],
    },
    {
        id: "268",
        brandName: "А-101",
        coords: [36.92653995724973, 34.891872161272445],
    },
    {
        id: "269",
        brandName: "А-101",
        coords: [36.928212652533894, 34.89855777620985],
    },
    {
        id: "270",
        brandName: "А-101",
        coords: [36.93596368812798, 34.88875901233212],
    },
    {
        id: "271",
        brandName: "А-101",
        coords: [36.942286317677286, 34.882992032992554],
    },
    {
        id: "272",
        brandName: "А-101",
        coords: [36.909688565440625, 34.89763914358062],
    },
    {
        id: "273",
        brandName: "MIGROS",
        coords: [36.38594985658669, 34.072992371680336],
    },
    {
        id: "274",
        brandName: "MIGROS",
        coords: [36.408472240000265, 34.07410817053912],
    },
    {
        id: "275",
        brandName: "MIGROS",
        coords: [36.41938564806271, 34.083807037542385],
    },
    {
        id: "276",
        brandName: "MIGROS",
        coords: [36.464128393404984, 34.141742752791416],
    },
    {
        id: "277",
        brandName: "MIGROS",
        coords: [36.506214260910504, 34.193855665792704],
    },
    {
        id: "278",
        brandName: "MIGROS",
        coords: [36.55416990142109, 34.2414464132681],
    },
    {
        id: "279",
        brandName: "MIGROS",
        coords: [36.60024828693245, 34.298247911884175],
    },
    {
        id: "280",
        brandName: "MIGROS",
        coords: [36.60210243865012, 34.305248858981706],
    },
    {
        id: "281",
        brandName: "MIGROS",
        coords: [36.60621617864335, 34.30864107046195],
    },
    {
        id: "282",
        brandName: "MIGROS",
        coords: [36.61085111682406, 34.315281150141274],
    },
    {
        id: "283",
        brandName: "MIGROS",
        coords: [36.64931879447313, 34.383883156409304],
    },
    {
        id: "284",
        brandName: "MIGROS",
        coords: [36.67644576082165, 34.410061514249975],
    },
    {
        id: "285",
        brandName: "MIGROS",
        coords: [36.70170517263326, 34.45134607978793],
    },
    {
        id: "286",
        brandName: "MIGROS",
        coords: [36.72723145586279, 34.501385367070235],
    },
    {
        id: "287",
        brandName: "MIGROS",
        coords: [36.735898830127994, 34.50979677385183],
    },
    {
        id: "288",
        brandName: "MIGROS",
        coords: [36.73871895657131, 34.52361552167199],
    },
    {
        id: "289",
        brandName: "MIGROS",
        coords: [36.74539054728318, 34.51863734214819],
    },
    {
        id: "290",
        brandName: "MIGROS",
        coords: [36.748554191699824, 34.544214885218736],
    },
    {
        id: "291",
        brandName: "MIGROS",
        coords: [36.76010726323481, 34.53142611368346],
    },
    {
        id: "292",
        brandName: "MIGROS",
        coords: [36.76925221091143, 34.55193964654877],
    },
    {
        id: "293",
        brandName: "MIGROS",
        coords: [36.773377394250616, 34.54060999659806],
    },
    {
        id: "294",
        brandName: "MIGROS",
        coords: [36.7784648146782, 34.54678980566208],
    },
    {
        id: "295",
        brandName: "MIGROS",
        coords: [36.78190206977095, 34.55417124426633],
    },
    {
        id: "296",
        brandName: "MIGROS",
        coords: [36.78203955676831, 34.56747499989027],
    },
    {
        id: "297",
        brandName: "MIGROS",
        coords: [36.78781378793233, 34.583439506638996],
    },
    {
        id: "298",
        brandName: "MIGROS",
        coords: [36.78712640227598, 34.587301887304015],
    },
    {
        id: "299",
        brandName: "MIGROS",
        coords: [36.789669698402676, 34.58910433161436],
    },
    {
        id: "300",
        brandName: "MIGROS",
        coords: [36.79599320392089, 34.59854570657328],
    },
    {
        id: "301",
        brandName: "MIGROS",
        coords: [36.79846747703737, 34.613823567870455],
    },
    {
        id: "302",
        brandName: "MIGROS",
        coords: [36.800116949029835, 34.63073221968118],
    },
    {
        id: "303",
        brandName: "MIGROS",
        coords: [36.80231618844743, 34.62601153220172],
    },
    {
        id: "304",
        brandName: "MIGROS",
        coords: [36.824992160882246, 34.61296526862211],
    },
    {
        id: "305",
        brandName: "MIGROS",
        coords: [36.8482795737092, 34.61760012542013],
    },
    {
        id: "306",
        brandName: "MIGROS",
        coords: [36.909718786485655, 34.88599895739342],
    },
    {
        id: "307",
        brandName: "MIGROS",
        coords: [36.92728572089391, 34.85793232456097],
    },
    {
        id: "308",
        brandName: "MIGROS",
        coords: [36.94045826587551, 34.865399593846675],
    },
    {
        id: "309",
        brandName: "Carrefour",
        coords: [36.49810263567446, 34.18360552323695],
    },
    {
        id: "310",
        brandName: "Carrefour",
        coords: [36.55534919578153, 34.241197914585904],
    },
    {
        id: "311",
        brandName: "Carrefour",
        coords: [36.60558823548323, 34.30662492203073],
    },
    {
        id: "312",
        brandName: "Carrefour",
        coords: [36.61206106710929, 34.31734232002199],
    },
    {
        id: "313",
        brandName: "Carrefour",
        coords: [36.65113231721263, 34.38195292601382],
    },
    {
        id: "314",
        brandName: "Carrefour",
        coords: [36.75401964092197, 34.51918435541339],
    },
    {
        id: "315",
        brandName: "Carrefour",
        coords: [36.750494122969066, 34.541427774361175],
    },
    {
        id: "316",
        brandName: "Carrefour",
        coords: [36.786325289223385, 34.578874406477794],
    },
    {
        id: "317",
        brandName: "Carrefour",
        coords: [36.79426826196512, 34.58814927285274],
    },
    {
        id: "318",
        brandName: "Carrefour",
        coords: [36.78912416659435, 34.59266019361019],
    },
    {
        id: "319",
        brandName: "Carrefour",
        coords: [36.80545968174587, 34.61960801904021],
    },
    {
        id: "320",
        brandName: "Carrefour",
        coords: [36.81105050930561, 34.62363176020403],
    },
    {
        id: "321",
        brandName: "Carrefour",
        coords: [36.92737709218502, 34.85081966022917],
    },
    {
        id: "322",
        brandName: "Carrefour",
        coords: [36.93106956600673, 34.869007687740286],
    },
    {
        id: "323",
        brandName: "Carrefour",
        coords: [36.93637718376583, 34.872472073932876],
    },
    {
        id: "324",
        brandName: "Carrefour",
        coords: [36.924492222563785, 34.876152984262504],
    },
    {
        id: "325",
        brandName: "Carrefour",
        coords: [36.9098353991526, 34.884886124456344],
    },
    {
        id: "326",
        brandName: "SOK",
        coords: [36.4962382185418, 34.182071457916514],
    },
    {
        id: "327",
        brandName: "SOK",
        coords: [36.499865307599805, 34.18638512279997],
    },
    {
        id: "328",
        brandName: "SOK",
        coords: [36.55371051036598, 34.241330505678924],
    },
    {
        id: "329",
        brandName: "SOK",
        coords: [36.600868979565945, 34.30189416464512],
    },
    {
        id: "330",
        brandName: "SOK",
        coords: [36.60359353715846, 34.30008241403231],
    },
    {
        id: "331",
        brandName: "SOK",
        coords: [36.602364425346, 34.30827356821135],
    },
    {
        id: "332",
        brandName: "SOK",
        coords: [36.60320432053672, 34.30643629998428],
    },
    {
        id: "333",
        brandName: "SOK",
        coords: [36.608100112916084, 34.30237899931616],
    },
    {
        id: "334",
        brandName: "SOK",
        coords: [36.61180759826848, 34.3011796714457],
    },
    {
        id: "335",
        brandName: "SOK",
        coords: [36.609861702766075, 34.30342522150101],
    },
    {
        id: "336",
        brandName: "SOK",
        coords: [36.60958821997288, 34.30713010368359],
    },
    {
        id: "337",
        brandName: "SOK",
        coords: [36.60651891803275, 34.309117748896526],
    },
    {
        id: "338",
        brandName: "SOK",
        coords: [36.60594645534649, 34.31016467652777],
    },
    {
        id: "339",
        brandName: "SOK",
        coords: [36.60402197516701, 34.314246177003184],
    },
    {
        id: "340",
        brandName: "SOK",
        coords: [36.60787088751054, 34.31104470265258],
    },
    {
        id: "341",
        brandName: "SOK",
        coords: [36.60966129710174, 34.31222818606181],
    },
    {
        id: "342",
        brandName: "SOK",
        coords: [36.607968325939396, 34.31620347745724],
    },
    {
        id: "343",
        brandName: "SOK",
        coords: [36.60997796396284, 34.317144195038935],
    },
    {
        id: "344",
        brandName: "SOK",
        coords: [36.611573457465035, 34.31834285131239],
    },
    {
        id: "345",
        brandName: "SOK",
        coords: [36.611183721641076, 34.3221360673676],
    },
    {
        id: "346",
        brandName: "SOK",
        coords: [36.636585505053276, 34.357261250993744],
    },
    {
        id: "347",
        brandName: "SOK",
        coords: [36.64042061723192, 34.3600682308746],
    },
    {
        id: "348",
        brandName: "SOK",
        coords: [36.643013775538684, 34.36941471656227],
    },
    {
        id: "349",
        brandName: "SOK",
        coords: [36.645278152089304, 34.37354173563034],
    },
    {
        id: "350",
        brandName: "SOK",
        coords: [36.648771978960035, 34.383161332699956],
    },

    {
        id: "326",
        brandName: "SOK",
        coords: [36.68863507782197, 34.42696253935726],
    },
    {
        id: "327",
        brandName: "SOK",
        coords: [36.68796949536747, 34.441289219757884],
    },
    {
        id: "328",
        brandName: "SOK",
        coords: [36.6909211649863, 34.43046301290603],
    },
    {
        id: "329",
        brandName: "SOK",
        coords: [36.69375697613335, 34.44197487952517],
    },
    {
        id: "330",
        brandName: "SOK",
        coords: [36.69824197410805, 34.45085236914369],
    },
    {
        id: "331",
        brandName: "SOK",
        coords: [36.699920164142675, 34.450527582938136],
    },
    {
        id: "332",
        brandName: "SOK",
        coords: [36.70524383538775, 34.462833374136736],
    },
    {
        id: "333",
        brandName: "SOK",
        coords: [36.7066036269395, 34.464385130452165],
    },
    {
        id: "334",
        brandName: "SOK",
        coords: [36.71409651472379, 34.47560829822192],
    },
    {
        id: "335",
        brandName: "SOK",
        coords: [36.71996880787741, 34.48618189358057],
    },
    {
        id: "336",
        brandName: "SOK",
        coords: [36.731422827149174, 34.49874029633447],
    },
    {
        id: "337",
        brandName: "SOK",
        coords: [36.729803170628045, 34.50599385492521],
    },
    {
        id: "338",
        brandName: "SOK",
        coords: [36.73269539044772, 34.50357600206163],
    },
    {
        id: "339",
        brandName: "SOK",
        coords: [36.73619483076116, 34.50021987793755],
    },
    {
        id: "340",
        brandName: "SOK",
        coords: [36.737987870601664, 34.501013799773354],
    },
    {
        id: "341",
        brandName: "SOK",
        coords: [36.73850842271074, 34.50718473767891],
    },
    {
        id: "342",
        brandName: "SOK",
        coords: [36.7345174329953, 34.514257859488794],
    },
    {
        id: "343",
        brandName: "SOK",
        coords: [36.741747334292526, 34.50548863193879],
    },
    {
        id: "344",
        brandName: "SOK",
        coords: [36.74411859360743, 34.50566906871965],
    },
    {
        id: "345",
        brandName: "SOK",
        coords: [36.74125572258279, 34.51122652157027],
    },
    {
        id: "346",
        brandName: "SOK",
        coords: [36.73816138836357, 34.51674788706472],
    },
    {
        id: "347",
        brandName: "SOK",
        coords: [36.73954951633858, 34.5242179697925],
    },
    {
        id: "348",
        brandName: "SOK",
        coords: [36.74252812288885, 34.527971054834474],
    },
    {
        id: "349",
        brandName: "SOK",
        coords: [36.74420534560776, 34.52641929851904],
    },
    {
        id: "350",
        brandName: "SOK",
        coords: [36.75235959577017, 34.52742974449188],
    },
    {
        id: "336",
        brandName: "SOK",
        coords: [36.753111362438545, 34.52616668702583],
    },
    {
        id: "337",
        brandName: "SOK",
        coords: [36.752937878476594, 34.52078967095607],
    },
    {
        id: "338",
        brandName: "SOK",
        coords: [36.743684832517054, 34.530533260540444],
    },
    {
        id: "339",
        brandName: "SOK",
        coords: [36.75473052754833, 34.53226545363674],
    },
    {
        id: "340",
        brandName: "SOK",
        coords: [36.75302462088883, 34.543236009913294],
    },
    {
        id: "341",
        brandName: "SOK",
        coords: [36.77694123336161, 34.55419161999979],
    },
    {
        id: "342",
        brandName: "SOK",
        coords: [36.77410849322555, 34.567435679715224],
    },
    {
        id: "343",
        brandName: "SOK",
        coords: [36.779571541184474, 34.579308419896094],
    },
    {
        id: "344",
        brandName: "SOK",
        coords: [36.7803808487842, 34.591830735264274],
    },
    {
        id: "345",
        brandName: "SOK",
        coords: [36.7821150499892, 34.59006245481181],
    },
    {
        id: "346",
        brandName: "SOK",
        coords: [36.78327116232532, 34.584288477824146],
    },
    {
        id: "347",
        brandName: "SOK",
        coords: [36.785987957663906, 34.59244422031921],
    },
    {
        id: "348",
        brandName: "SOK",
        coords: [36.78578564643667, 34.59879559500563],
    },
    {
        id: "349",
        brandName: "SOK",
        coords: [36.7910455647481, 34.5968468777723],
    },
    {
        id: "350",
        brandName: "SOK",
        coords: [36.79321300844265, 34.60218780648588],
    },
    {
        id: "336",
        brandName: "SOK",
        coords: [36.785756744789154, 34.60539958118527],
    },
    {
        id: "337",
        brandName: "SOK",
        coords: [36.79081436713477, 34.61676709837971],
    },
    {
        id: "338",
        brandName: "SOK",
        coords: [36.79364648982305, 34.62127801790132],
    },
    {
        id: "339",
        brandName: "SOK",
        coords: [36.79714314992575, 34.6240928316828],
    },
    {
        id: "340",
        brandName: "SOK",
        coords: [36.80127536062447, 34.6194375627365],
    },
    {
        id: "341",
        brandName: "SOK",
        coords: [36.80393372797437, 34.614493594940825],
    },
    {
        id: "342",
        brandName: "SOK",
        coords: [36.805147299779996, 34.613663585748846],
    },
    {
        id: "343",
        brandName: "SOK",
        coords: [36.804540516281264, 34.62398456961429],
    },
    {
        id: "344",
        brandName: "SOK",
        coords: [36.81540796365073, 34.63335469408014],
    },
    {
        id: "345",
        brandName: "SOK",
        coords: [36.812556300507666, 34.6430535610834],
    },
    {
        id: "346",
        brandName: "SOK",
        coords: [36.82176369801297, 34.65026333832477],
    },
    {
        id: "347",
        brandName: "SOK",
        coords: [36.82506160081239, 34.6476455025407],
    },
    {
        id: "348",
        brandName: "SOK",
        coords: [36.82502660669936, 34.647602588225205],
    },
    {
        id: "349",
        brandName: "SOK",
        coords: [36.82165999454072, 34.649877101283494],
    },
    {
        id: "350",
        brandName: "SOK",
        coords: [36.83467903061226, 34.68772843321396],
    },
    {
        id: "344",
        brandName: "SOK",
        coords: [36.924704796160285, 34.85718302629773],
    },
    {
        id: "345",
        brandName: "SOK",
        coords: [36.93033271274822, 34.85845754689229],
    },
    {
        id: "346",
        brandName: "SOK",
        coords: [36.93363164309813, 34.86677227648532],
    },
    {
        id: "347",
        brandName: "SOK",
        coords: [36.93717298294043, 34.87617945230226],
    },
    {
        id: "348",
        brandName: "SOK",
        coords: [36.940762666333946, 34.88467625626594],
    },
    {
        id: "349",
        brandName: "SOK",
        coords: [36.94614687435575, 34.875572537733426],
    },
    {
        id: "350",
        brandName: "SOK",
        coords: [36.91859671198267, 34.88761824823535],
    },
    {
        id: "344",
        brandName: "SOK",
        coords: [36.91739583934827, 34.89392680332154],
    },
    {
        id: "345",
        brandName: "SOK",
        coords: [36.91001863545687, 34.89190978230759],
    },
    {
        id: "346",
        brandName: "SOK",
        coords: [36.93430924552468, 34.92761534861539],
    },
    // {
    //     id: "347",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "348",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "349",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "350",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "344",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "345",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "346",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "347",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "348",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "349",
    //     brandName: "SOK",
    //     coords: [],
    // },
    // {
    //     id: "350",
    //     brandName: "SOK",
    //     coords: [],
    // },
];
