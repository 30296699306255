import "./menuButtonsBlock.css";
import MenuButton from "../MenuButton/MenuButton";

const MenuButtonsBlock = ({ houseId }) => {
    const buttons = [
        { id: 1, name: "ЖК", link: "/houses/" },
        { id: 2, name: "ИНТЕРЬЕР", link: "/interior/" },
        { id: 3, name: "ПЛАНИРОВКИ", link: "/plan/" },
        { id: 4, name: "ПЛАН ЭТАЖА", link: "/floor/" },
        { id: 5, name: "ПАНОРАМА", link: "/panorama/" },
        { id: 7, name: "ВИДЕО", link: "/video/" },
        { id: 8, name: "ИНФОРМАЦИЯ", link: "/info/" },
        { id: 9, name: "ИНФРАСТРУКТУРА", link: "/infrastructure/" },
    ];
    return (
        <div className='nav_wrapper'>
            <div className='nav_buttons_container'>
                {buttons.map((button) => (
                    <MenuButton
                        key={button.id}
                        name={button.name}
                        houseId={houseId}
                        link={button.link}
                    />
                ))}
            </div>
        </div>
    );
};

export default MenuButtonsBlock;
