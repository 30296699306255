import "./header.css";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { agents } from "../../DBs/agents";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import { useEffect, useState } from "react";

const Header = () => {
    const [nav, setNav] = useState(false);

    const reloadPage = () => {
        window.location.reload();
    };

    const favoriteObjects = JSON.parse(localStorage.getItem("favorites"));

    const handlePrimaryProperty = () => {
        setNav(!nav);
        localStorage.setItem("propertyType", "primary");
        setTimeout(reloadPage, 1000);
    };

    const handleResaleProperty = () => {
        setNav(!nav);
        localStorage.setItem("propertyType", "resale");
        setTimeout(reloadPage, 1000);
    };

    const handlePrimaryAlanyaProperty = () => {
        setNav(!nav);
        localStorage.setItem("propertyType", "primaryAlanya");
        setTimeout(reloadPage, 1000);
    };

    const handleResaleAlanyaProperty = () => {
        setNav(!nav);
        localStorage.setItem("propertyType", "resaleAlanya");
        setTimeout(reloadPage, 1000);
    };

    const removeUserData = () => {
        localStorage.setItem("user1", "");
    };
    const user = localStorage.getItem("user1");

    const objectUser = JSON.parse(user);

    const deletedUsersEmail = [
        // "dimass82@mail.ru",
        "andreika453@gmail.com",
        "tsavkina91@gmail.com",
        "e777kx186rus@gmail.com",
        "9046473198@yandex.ru",
        "yarri1302@gmail.com",
        "his2011@rambler.ru",
        "anar.aryn@gmail.com",
        "zhenis.ibrai@bk.ru",
        "sanchezsafr@gmail.com",
        "yavid-as@yandex.ru",
        "daniyar.abdiresh@gmail.com",
        "info@eronyapi.com",
        "baldim65@mail.ru",
        "yaroslavbalakin161097@gmail.com",
        "timurtt@mail.ru",
        "gulsina-a2013@yandex.ru",
        "rafaelhovsepyan92@gmail.com",
        "acrobatca@mail.ru",
        "danilu6a@icloud.com",
        "7787418@gmail.com",
        "katrin77_09@mail.ru",
    ];

    const deleted = deletedUsersEmail.find(
        (email) => email === objectUser.email,
    );

    if (deleted) {
        removeUserData();
    }

    const agent = agents.find((i) => i.email === objectUser.email);

    return (
        <nav className='header'>
            <Link to='/'>
                <div>
                    <div data-aos='flip-right' data-aos-once='false'>
                        <img className='logo_image' src='/mr.jpg' alt='logo' />
                    </div>
                </div>
            </Link>

            <div className='agent_wrapper'>
                <div className='favorites'>
                    <Link to='/favorites'>
                        <FavoriteIcon style={{ color: "red" }} />
                    </Link>
                </div>
                <div className='favorites_length'>
                    {favoriteObjects ? favoriteObjects.length : "0"}
                </div>
                {agent.avatarUrl ? (
                    <img
                        className='agent_avatar'
                        data-aos='flip-right'
                        data-aos-once='false'
                        src={agent.avatarUrl}
                        alt='avatar'
                    />
                ) : (
                    <PersonIcon />
                )}
                {objectUser.lastName[0].toUpperCase() +
                    objectUser.lastName.slice(1) +
                    " " +
                    objectUser.firstName[0].toUpperCase() +
                    "."}
            </div>
            <ul className={nav ? "burger active" : "burger"}>
                <li className='burger_btn' onClick={handlePrimaryProperty}>
                    Новостройки Мерсина
                </li>
                <li className='burger_btn' onClick={handleResaleProperty}>
                    Вторичная недвижимость Мерсина
                </li>
                <li
                    className='burger_btn'
                    onClick={handlePrimaryAlanyaProperty}
                >
                    Новостройки Алании
                </li>
                <li className='burger_btn' onClick={handleResaleAlanyaProperty}>
                    Вторичная недвижимость Алании
                </li>
                <li>
                    <a
                        className='burger_btn'
                        style={{
                            color: "#000000",
                            textDecoration: "none ",
                            fontSize: "20px",
                        }}
                        href='/login'
                        onClick={removeUserData}
                    >
                        Выйти из личного кабинета
                    </a>
                </li>
            </ul>
            <div
                onClick={() => setNav(!nav)}
                style={{ width: "80px", height: "auto" }}
            >
                {nav ? (
                    <CloseIcon
                        style={{
                            display: "block",
                            position: "absolute",
                            right: "20px",
                            top: "12px",
                            width: "40px",
                            height: "auto",
                            cursor: "pointer",
                            zIndex: "101",
                            color: "#000000",
                        }}
                    />
                ) : (
                    <MenuIcon
                        fontSize='large'
                        style={{
                            display: "block",
                            position: "absolute",
                            right: "20px",
                            top: "12px",
                            width: "40px",
                            height: "auto",
                            cursor: "pointer",
                            zIndex: "101",
                        }}
                    />
                )}
            </div>
        </nav>
    );
};

export default Header;

// import { useEffect, useState } from "react";
// import { agents } from "../../DBs/agents";
// import { Link } from "react-router-dom";
// import "./header.css";
// import PersonIcon from "@mui/icons-material/Person";
// import FavoriteIcon from "@mui/icons-material/Favorite";

// const Header = () => {
//     const [user, setUser] = useState("");
//     const favoriteObjects = JSON.parse(localStorage.getItem("favorites"));

//     const removeUserData = () => {
//         localStorage.setItem("user1", "");
//     };

//     const objectUser = JSON.parse(user);
//     const agent = agents.find((i) => i.email === objectUser?.email);

//     useEffect(() => {
//         setUser(localStorage.getItem("user1"));
//     }, []);

//     return (
//         <div className='agent_wrapper'>
//             <div className='favorites'>
//                 <Link to='/favorites'>
//                     <FavoriteIcon style={{ color: "red" }} />
//                 </Link>
//             </div>
//             <div className='favorites_length'>
//                 {favoriteObjects ? favoriteObjects.length : "0"}
//             </div>
//             {agent?.avatarUrl ? (
//                 <img
//                     className='agent_avatar'
//                     data-aos='flip-right'
//                     data-aos-once='false'
//                     src={agent.avatarUrl}
//                     alt='avatar'
//                 />
//             ) : (
//                 <PersonIcon />
//             )}
//             {`${objectUser?.lastName[0].toUpperCase()}${objectUser?.lastName.slice(
//                 1,
//             )} ${objectUser?.firstName[0].toUpperCase()}.`}
//             <button
//                 className='uppercase style_btn'
//                 style={{ marginLeft: "15px" }}
//                 onClick={removeUserData}
//             >
//                 <a href='/login'>Выйти</a>
//             </button>
//         </div>
//     );
// };

// export default Header;
