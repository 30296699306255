import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header/Header";

export default function FavoritePage() {
    const [favoriteObjects, setObjects] = useState([]);

    const removeObject = (index) => {
        const newFavoriteObjects = [...favoriteObjects];
        newFavoriteObjects.splice(index, 1);
        setObjects(newFavoriteObjects);
        localStorage.setItem("favorites", JSON.stringify(newFavoriteObjects));
    };

    const removeALLObjects = () => {
        setObjects([]);
        localStorage.setItem("favorites", JSON.stringify([]));
    };

    useEffect(() => {
        setObjects(JSON.parse(localStorage.getItem("favorites")));
    }, []);
    return (
        <div>
            <Header />
            {/* в процессе */}
            {favoriteObjects && document.documentElement.clientWidth > 950 ? (
                <table>
                    <thead
                        style={{
                            background: "lightGrey",
                        }}
                    >
                        <tr>
                            <th>ID</th>
                            <th>Район</th>
                            <th>Фото</th>
                            <th>Название</th>
                            <th>Сдача</th>
                            <th>Планировки</th>
                            <th>Цена от</th>
                            <th>До моря</th>
                            <th>PDF</th>
                            <th>Перейти</th>
                            <th>Удалить</th>
                        </tr>
                    </thead>
                    <tbody>
                        {favoriteObjects.map((obj, index) => (
                            <tr key={index} style={{ height: "100px" }}>
                                <td>{obj.id}</td>
                                <td>{obj.district}</td>
                                <td>
                                    <img
                                        style={{
                                            height: "80px",
                                            width: "130px",
                                        }}
                                        src={obj.image}
                                    />
                                </td>
                                <td>{obj.name}</td>
                                <td>{obj.date}</td>
                                <td>{obj.roomsQuantity}</td>
                                <td>{obj.minPrice} €</td>
                                <td>{obj.distance} м</td>
                                <td>
                                    <button
                                        className='btn style_btn'
                                        style={{
                                            width: "110px",
                                        }}
                                    >
                                        {" "}
                                        <a href={obj.pdf} download>
                                            PDF
                                        </a>
                                    </button>
                                </td>
                                <td>
                                    <Link to={`/houses/${obj.id}`}>
                                        <button
                                            className='btn style_btn'
                                            style={{
                                                width: "110px",
                                            }}
                                        >
                                            Перейти
                                        </button>
                                    </Link>
                                </td>
                                <td>
                                    <button
                                        onClick={() => removeObject(index)}
                                        className='btn style_btn'
                                        style={{
                                            backgroundColor: "red",
                                            width: "110px",
                                        }}
                                    >
                                        Удалить
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : favoriteObjects &&
              document.documentElement.clientWidth < 950 ? (
                <div
                    className='cards_container'
                    style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {favoriteObjects.map((obj, index) => {
                        return (
                            <div
                                key={index}
                                className='card'
                                style={{
                                    width: "320px",
                                    height: "auto",
                                    // border: "1px solid black",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "10px 5px 5px lightGrey",
                                    marginRight: "10px",
                                }}
                            >
                                <img
                                    alt='img'
                                    style={{ width: "320px", height: "190px" }}
                                    src={obj.image}
                                />
                                <h6>{obj.name}</h6>
                                <b>ID: {obj.id}</b>
                                <b>{obj.district}</b>
                                <div
                                    style={{
                                        marginTop: "10px",
                                        width: "320px",
                                        height: "auto",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "space-around",
                                    }}
                                >
                                    <div
                                        style={{
                                            paddingLeft: "10px",
                                            width: "160px",
                                            height: "auto",
                                            fontWeight: "bold",
                                            // border: "1px solid red",
                                        }}
                                    >
                                        <div>Сдача:</div>
                                        <div>Планировки:</div>
                                        <div>Цена от:</div>
                                        <div>До моря:</div>
                                    </div>
                                    <div
                                        style={{
                                            paddingLeft: "10px",
                                            width: "160px",
                                            height: "auto",
                                            // border: "1px solid yellow",
                                        }}
                                    >
                                        <div>{obj.date}</div>
                                        <div>{obj.roomsQuantity}</div>
                                        <div>{obj.minPrice} €</div>
                                        <div>{obj.distance} м</div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "320px",
                                        display: "flex",
                                        flexDirection: "row",
                                        margin: "20px auto",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <button
                                        className='btn style_btn'
                                        style={{
                                            width: "90px",
                                            fontSize: "12px",
                                        }}
                                    >
                                        <a href={obj.pdf} download>
                                            PDF
                                        </a>
                                    </button>
                                    <Link to={`/houses/${obj.id}`}>
                                        <button
                                            className='btn style_btn'
                                            style={{
                                                width: "90px",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Перейти
                                        </button>
                                    </Link>
                                    <button
                                        onClick={() => removeObject(index)}
                                        className='btn style_btn'
                                        style={{
                                            backgroundColor: "red",
                                            width: "90px",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Удалить
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <h6
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    В избранном пока нет объектов
                </h6>
            )}
            <button
                onClick={removeALLObjects}
                className='btn style_btn'
                style={{
                    backgroundColor: "red",
                    width: "300px",
                    margin: "0 auto",
                    marginBottom: "15px",
                    display: "block",
                }}
            >
                Удалить все
            </button>
        </div>
    );
}
