import MarkerItem from "./MarkerItem/MarkerItem";

const MarkerList = ({
    filteredCatalog = [],
    postQuery,
    year,
    price,
    district,
    roomsQuantity,
}) => {
    return (
        <div className='list'>
            {price.length && roomsQuantity === ""
                ? filteredCatalog
                      .filter((catalogItem) =>
                          catalogItem.name.toLowerCase().includes(postQuery),
                      )
                      .filter((catalogItem) =>
                          catalogItem.year.toString().includes(year),
                      )
                      .filter((catalogItem) =>
                          catalogItem.district.includes(district),
                      )
                      .filter((catalogItem) =>
                          catalogItem.roomsQuantity.includes(roomsQuantity),
                      )

                      .filter(
                          (catalogItem) =>
                              Number(catalogItem.minPrice) <= Number(price),
                      )
                      .map((marker) => (
                          <MarkerItem key={marker.name} {...marker} />
                      ))
                : price.length && roomsQuantity === "1+0"
                ? filteredCatalog
                      .filter((catalogItem) =>
                          catalogItem.name.toLowerCase().includes(postQuery),
                      )
                      .filter((catalogItem) =>
                          catalogItem.year.toString().includes(year),
                      )
                      .filter((catalogItem) =>
                          catalogItem.district.includes(district),
                      )
                      .filter((catalogItem) =>
                          catalogItem.roomsQuantity.includes(roomsQuantity),
                      )

                      .filter(
                          (catalogItem) =>
                              catalogItem.studioPrice.length &&
                              Number(catalogItem.studioPrice) <= Number(price),
                      )
                      .map((marker) => (
                          <MarkerItem key={marker.name} {...marker} />
                      ))
                : price.length && roomsQuantity === "1+1"
                ? filteredCatalog
                      .filter((catalogItem) =>
                          catalogItem.name.toLowerCase().includes(postQuery),
                      )
                      .filter((catalogItem) =>
                          catalogItem.year.toString().includes(year),
                      )
                      .filter((catalogItem) =>
                          catalogItem.district.includes(district),
                      )
                      .filter((catalogItem) =>
                          catalogItem.roomsQuantity.includes(roomsQuantity),
                      )

                      .filter(
                          (catalogItem) =>
                              catalogItem.oneBedroomsPrice.length &&
                              Number(catalogItem.oneBedroomsPrice) <=
                                  Number(price),
                      )
                      .map((marker) => (
                          <MarkerItem key={marker.name} {...marker} />
                      ))
                : price.length && roomsQuantity === "2+1"
                ? filteredCatalog
                      .filter((catalogItem) =>
                          catalogItem.name.toLowerCase().includes(postQuery),
                      )
                      .filter((catalogItem) =>
                          catalogItem.year.toString().includes(year),
                      )
                      .filter((catalogItem) =>
                          catalogItem.district.includes(district),
                      )
                      .filter((catalogItem) =>
                          catalogItem.roomsQuantity.includes(roomsQuantity),
                      )

                      .filter(
                          (catalogItem) =>
                              catalogItem.twoBedroomsPrice.length &&
                              Number(catalogItem.twoBedroomsPrice) <=
                                  Number(price),
                      )
                      .map((marker) => (
                          <MarkerItem key={marker.name} {...marker} />
                      ))
                : price.length && roomsQuantity === "3+1"
                ? filteredCatalog
                      .filter((catalogItem) =>
                          catalogItem.name.toLowerCase().includes(postQuery),
                      )
                      .filter((catalogItem) =>
                          catalogItem.year.toString().includes(year),
                      )
                      .filter((catalogItem) =>
                          catalogItem.district.includes(district),
                      )
                      .filter((catalogItem) =>
                          catalogItem.roomsQuantity.includes(roomsQuantity),
                      )

                      .filter(
                          (catalogItem) =>
                              catalogItem.threeBedroomPrice.length &&
                              Number(catalogItem.threeBedroomPrice) <=
                                  Number(price),
                      )
                      .map((marker) => (
                          <MarkerItem key={marker.name} {...marker} />
                      ))
                : filteredCatalog
                      .filter((catalogItem) =>
                          catalogItem.name.toLowerCase().includes(postQuery),
                      )
                      .filter((catalogItem) =>
                          catalogItem.year.toString().includes(year),
                      )
                      .filter((catalogItem) =>
                          catalogItem.district.includes(district),
                      )
                      .filter((catalogItem) =>
                          catalogItem.roomsQuantity.includes(roomsQuantity),
                      )
                      .map((marker) => (
                          <MarkerItem key={marker.name} {...marker} />
                      ))}
        </div>
    );
};

export default MarkerList;
