import "./footer.css";

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer_copyright'>
                © {new Date().getFullYear()} Copyright OWL Group
            </div>
        </footer>
    );
};

export default Footer;
