import "./searchFilters.css";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

export default function SearchFilters({
    setSearchParams,
    postQuery,
    price,
    district,
    roomsQuantity,
    year,
}) {
    const [active, setActive] = useState(false);
    const [search, setSearch] = useState(postQuery);
    const [checkPrice, setCheckPrice] = useState(price);
    const [checkDistrict, setCheckDistrict] = useState(district);
    const [checkRoomsQuantity, setCheckRoomsQuantity] = useState(roomsQuantity);
    const [checkYear, setCheckYear] = useState(year);

    const propertyStatus = localStorage.getItem("propertyType");

    const years = ["2022", "2023", "2024", "2025", "2026"];
    const prices = [
        "40000",
        "50000",
        "60000",
        "70000",
        "80000",
        "90000",
        "100000",
        "110000",
        "140000",
        "170000",
        "200000",
        "230000",
        "280000",
        "320000",
        "360000",
    ];
    const rooms = ["1+0", "1+1", "2+1", "3+1"];
    const districts = [
        "Арпачбахшиш",
        "Аяш",
        "Енишехир",
        "Каргыпынары",
        "Коджахасанлы",
        "Кумкую",
        "Мезитли",
        "Тарсус",
        "Тедже",
        "Томюк",
        "Чешмели",
        "Эрдемли",
    ];

    const districtsAlanya = [
        "Авсаллар",
        "Каргыджак",
        "Махмутлар",
        "Центр Алании",
        "Тосмур",
        "Оба",
        "Паяллар",
        "Демирташ",
        "Газипаша",
        "Окурджалар",
        "Инджектум",
    ];

    const handleChange = () => {
        setActive(!active);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        const query = form.search.value.toLowerCase();
        const formPrice = checkPrice;
        const formDistrict = checkDistrict;
        const formYear = checkYear;
        const formRoomsQuantity = checkRoomsQuantity;

        const params = {};
        if (query.length) params.post = query;
        if (formPrice.length) params.price = formPrice;
        if (formDistrict.length) params.district = formDistrict;
        if (formYear.length) params.year = formYear;
        if (formRoomsQuantity.length) params.roomsquantity = formRoomsQuantity;
        setSearchParams(params);
    };

    return (
        <div className='filters_container'>
            {active === true ? (
                <>
                    <div
                        className='icon_container_close'
                        onClick={handleChange}
                    >
                        <CloseIcon style={{ color: "white" }} />
                    </div>
                    <form autoComplete='off' onSubmit={handleSubmit}>
                        <input
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            type='search'
                            name='search'
                            placeholder='ЖК'
                        />
                        <div className='filter_item_wrapper'>
                            <div className='filter_description'>
                                Стоимость до
                            </div>
                            <select
                                value={checkPrice}
                                onChange={(e) => {
                                    setCheckPrice(e.target.value);
                                }}
                            >
                                <option id='Все' value=''>
                                    Все
                                </option>
                                {prices.map((price) => (
                                    <option id={price} value={price}>
                                        {price}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {propertyStatus === ("primary" || "resale") ? (
                            <div className='filter_item_wrapper'>
                                <div className='filter_description'>
                                    Локация
                                </div>
                                <select
                                    value={checkDistrict}
                                    onChange={(e) => {
                                        setCheckDistrict(e.target.value);
                                    }}
                                >
                                    <option id='Все' value=''>
                                        Все
                                    </option>
                                    {districts.map((district) => (
                                        <option id={district} value={district}>
                                            {district}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            <div className='filter_item_wrapper'>
                                <div className='filter_description'>
                                    Локация
                                </div>
                                <select
                                    value={checkDistrict}
                                    onChange={(e) => {
                                        setCheckDistrict(e.target.value);
                                    }}
                                >
                                    <option id='Все' value=''>
                                        Все
                                    </option>
                                    {districtsAlanya.map((district) => (
                                        <option id={district} value={district}>
                                            {district}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className='filter_item_wrapper'>
                            <div className='filter_description'>
                                Сдача объекта
                            </div>
                            <select
                                id={year}
                                value={checkYear}
                                onChange={(e) => {
                                    setCheckYear(e.target.value);
                                }}
                            >
                                <option id='Все' value=''>
                                    Все
                                </option>
                                {years.map((year) => (
                                    <option id={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='filter_item_wrapper'>
                            <div className='filter_description'>Планировка</div>
                            <select
                                value={checkRoomsQuantity}
                                onChange={(e) => {
                                    setCheckRoomsQuantity(e.target.value);
                                }}
                            >
                                <option id='Все' value=''>
                                    Все
                                </option>
                                {rooms.map((room) => (
                                    <option id={room} value={room}>
                                        {room}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <input
                            className='search_btn'
                            type='submit'
                            value='Поиск'
                        />
                    </form>
                </>
            ) : (
                <div className='icon_container_search' onClick={handleChange}>
                    <SearchIcon style={{ color: "white" }} />
                </div>
            )}
        </div>
    );
}
