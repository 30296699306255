import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { fireDb } from "../fireConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cryptojs from "crypto-js";
import moment from "moment";
import emailjs from "emailjs-com";
// import { useRef } from "react";

const RegisterPage = () => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        mode: "onBlur",
    });

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        try {
            //check if user already exists based on email

            const qry = query(
                collection(fireDb, "users"),
                where("email", "==", data.email.toLowerCase()),
            );

            const existingUsers = await getDocs(qry);

            if (existingUsers.size > 0) {
                toast.warning("Пользователь уже существует.");
                return;
            } else {
                // encrypt password

                const encryptedPassword = cryptojs.AES.encrypt(
                    data.password,
                    "secret",
                ).toString();

                // получаем название месяца, день месяца, год, время
                var now = moment().format("DD/MM/YYYY HH:mm:ss A");

                const response = await addDoc(collection(fireDb, "users"), {
                    firstName: data.firstName.toLowerCase(),
                    lastName: data.lastName.toLowerCase(),
                    email: data.email.toLowerCase(),
                    password: encryptedPassword,
                    access: true,
                    date: now,
                });
                if (response.id) {
                    toast.success("Пользователь успешно создан.");
                    navigate(-1);
                } else {
                    toast.error("Не удалось создать пользователя.");
                }
                reset();
            }
        } catch (error) {
            console.error(error);
            toast.error("Что-то пошло не так...");
        }
    };

    return (
        <>
            <ToastContainer />
            <div className='wrapper'>
                <h3 style={{ textAlign: "center" }}>Регистрация</h3>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <label>
                        Фамилия
                        <input
                            name='name'
                            {...register("firstName", {
                                required: "Поле обязательно к заполнению",
                                minLength: {
                                    value: 3,
                                    message: "Минимум 3 символа",
                                },
                            })}
                        />
                    </label>
                    <div style={{ height: 40, color: "red" }}>
                        {errors?.firstName && (
                            <p>{errors?.firstName?.message || "Error!"}</p>
                        )}
                    </div>
                    <label>
                        Имя
                        <input
                            {...register("lastName", {
                                required: "Поле обязательно к заполнению",
                                minLength: {
                                    value: 3,
                                    message: "Минимум 3 символа",
                                },
                            })}
                        />
                    </label>
                    <div style={{ height: 40, color: "red" }}>
                        {errors?.lastName && (
                            <p>{errors?.lastName?.message || "Error!"}</p>
                        )}
                    </div>
                    <label>
                        Email
                        <input
                            name='email'
                            {...register("email", {
                                required: "Поле обязательно к заполнению",
                                minLength: {
                                    value: 5,
                                    message: "Минимум 5 символов",
                                },
                                pattern:
                                    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
                            })}
                        />
                    </label>
                    <div style={{ height: 40, color: "red" }}>
                        {errors?.email && (
                            <p>{errors?.email?.message || "Error!"}</p>
                        )}
                    </div>
                    <label>
                        Пароль
                        <input
                            type='password'
                            {...register("password", {
                                required: "Поле обязательно к заполнению",
                                minLength: {
                                    value: 8,
                                    message: "Минимум 10 символов",
                                },
                            })}
                        />
                    </label>
                    <div style={{ height: 40, color: "red" }}>
                        {errors?.password && (
                            <p>{errors?.password?.message || "Error!"}</p>
                        )}
                    </div>
                    <input type='submit' />
                    <Link to='/login'>
                        <p
                            style={{
                                color: "#26a69a",
                                textDecoration: "underline",
                            }}
                        >
                            Уже зарегистрированы? Пройдите авторизацию.
                        </p>
                    </Link>
                </form>
            </div>
        </>
    );
};

export default RegisterPage;
