// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDIGpBKz8dbmU2P9REaIeXWvnxc-GzbbkI",
    authDomain: "real-estate-3ab48.firebaseapp.com",
    databaseURL:
        "https://real-estate-3ab48-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "real-estate-3ab48",
    storageBucket: "real-estate-3ab48.appspot.com",
    messagingSenderId: "282533730407",
    appId: "1:282533730407:web:5bcf72ebcd3aa24fc89cc2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore DB
const fireDb = getFirestore(app);

export {fireDb, app}