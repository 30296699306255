import { collection, getDocs, query, where } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { fireDb } from "../fireConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cryptojs from "crypto-js";

const LoginPage = () => {
    const navigate = useNavigate();

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        mode: "onBlur",
    });

    const onSubmit = async (data) => {
        localStorage.setItem("user1", []);
        try {
            const qry = query(
                collection(fireDb, "users"),
                where("email", "==", data.email.toLowerCase()),
            );

            const existingUsers = await getDocs(qry);
            if (existingUsers.size > 0) {
                // decrypt password

                const decryptedPassword = cryptojs.AES.decrypt(
                    existingUsers.docs[0].data().password,
                    "secret",
                ).toString(cryptojs.enc.Utf8);
                if (decryptedPassword === data.password) {
                    toast.success("Пользователь успешно авторизовался.");
                    reset();

                    const dataToPutInLocalStorage = {
                        firstName: existingUsers.docs[0].data().firstName,
                        lastName: existingUsers.docs[0].data().lastName,
                        email: existingUsers.docs[0].data().email,
                        id: existingUsers.docs[0].id,
                        access: existingUsers.docs[0].data().access,
                    };
                    localStorage.setItem(
                        "user1",
                        JSON.stringify(dataToPutInLocalStorage),
                    );
                    navigate("/");
                } else {
                    toast.error("Hедействительные учетные данные.");
                }
            } else {
                toast.warning("Пользователь не найден.");
            }
        } catch (error) {
            console.error(error);
            toast.error("Что-то пошло не так...");
        }
    };

    return (
        <>
            <ToastContainer />
            <div className='wrapper'>
                <h3 style={{ textAlign: "center" }}>Авторизация</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <label>
                        Email
                        <input
                            {...register("email", {
                                required: "Поле обязательно к заполнению",
                                minLength: {
                                    value: 5,
                                    message: "Минимум 5 символов",
                                },
                                pattern:
                                    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
                            })}
                        />
                    </label>
                    <div style={{ height: 40, color: "red" }}>
                        {errors?.email && (
                            <p>{errors?.email?.message || "Error!"}</p>
                        )}
                    </div>
                    <label>
                        Пароль
                        <input
                            type='password'
                            {...register("password", {
                                required: "Поле обязательно к заполнению",
                                minLength: {
                                    value: 8,
                                    message: "Минимум 8 символов",
                                },
                            })}
                        />
                    </label>
                    <div style={{ height: 40, color: "red" }}>
                        {errors?.password && (
                            <p>{errors?.password?.message || "Error!"}</p>
                        )}
                    </div>
                    <input type='submit' />
                    <Link to=''>
                        {/* <Link to='/register'> */}
                        <p
                            style={{
                                color: "#26a69a",
                                textDecoration: "underline",
                            }}
                        >
                            Нет аккаунта? Зарегистрируйтесь.
                        </p>
                    </Link>
                </form>
            </div>
        </>
    );
};

export default LoginPage;
