import "./infrastructurePage.css";
import { useParams } from "react-router-dom";
import ButtonsBlock from "../../components/ButtonsBlock/ButtonsBlock";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import MenuButtonsBlock from "../../components/MenuButtonsBlock/MenuButtonsBlock";
import Preloader from "../../components/Preloader";

const InfrastructurePage = (props) => {
    const { db, error } = props;
    const { id } = useParams();

    const containerStyles = {
        maxWidth: "600px",
        maxHeight: "650px",
        margin: 0,
    };

    const getFiltered = (id) => {
        const filteredDB = db.find((i) => i.id === id);

        const name = filteredDB.name;
        const itemId = filteredDB.id;
        const streetView = filteredDB.streetview;
        const slides = filteredDB.houseurls;
        const houseId = filteredDB.id;
        const linkToPrice = filteredDB.price;
        const infrastructure = filteredDB.infrastructure;
        const dateOfPutting = filteredDB.dateofputting;
        const roomsQuantity = filteredDB.roomsQuantity;
        const district = filteredDB.district;
        const minPrice = filteredDB.minPrice;
        const distance = filteredDB.distance;
        const pdf = filteredDB.pdf;

        return (
            <>
                <MenuButtonsBlock houseId={houseId} />
                <div className='infrastructure_container'>
                    <div className='block'>
                        <div style={containerStyles}>
                            <div className='residental_name' key={itemId}>
                                {name}
                            </div>
                            <div data-aos='zoom-in'>
                                <div style={containerStyles}>
                                    <ImageSlider slides={slides} />
                                </div>
                            </div>
                            <ButtonsBlock
                                streetView={streetView}
                                linkToPrice={linkToPrice}
                                distance={distance}
                                minPrice={minPrice}
                                district={district}
                                roomsQuantity={roomsQuantity}
                                dateOfPutting={dateOfPutting}
                                name={name}
                                itemId={itemId}
                                slides={slides}
                                pdf={pdf}
                            />
                        </div>
                        <div className='information'>
                            <p>
                                <p
                                    style={{
                                        fontSize: "30px",
                                        fontWeight: 800,
                                    }}
                                >
                                    Инфраструктурa:
                                </p>
                            </p>
                            <p style={{ fontWeight: 500 }}>{infrastructure}</p>
                            <hr />
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            {db ? (
                getFiltered(id)
            ) : error ? (
                <div>Sorry, we will fix it soon ...</div>
            ) : (
                <Preloader />
            )}
        </>
    );
};

export default InfrastructurePage;
