import { useState, useEffect } from "react";

export const useFetch = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const propertyStatus = localStorage.getItem("propertyType");

    // Устанавливаем значение "primary" для propertyType, если оно отсутствует
    !propertyStatus && localStorage.setItem("propertyType", "primary");
    // получаем обновленный propertyType 
    const status = localStorage.getItem("propertyType");

    useEffect(() => {
        async function fetchData() {
            if (status === "primary") {
                try {
                    const json = await fetch("DataBases/DB.json");
                    const res = await json.json();
                    setResponse(res);
                } catch (err) {
                    setError(err);
                }
            }
            if (status === "resale") {
                try {
                    const json = await fetch("DataBases/resaleDB.json");
                    const res = await json.json();
                    setResponse(res);
                } catch (err) {
                    setError(err);
                }
            }
            if (status === "primaryAlanya") {
                try {
                    const json = await fetch("DataBases/DBAlanya.json");
                    const res = await json.json();
                    setResponse(res);
                } catch (err) {
                    setError(err);
                }
            }
            if (status === "resaleAlanya") {
                try {
                    const json = await fetch("DataBases/resaleDBAlanya.json");
                    const res = await json.json();
                    setResponse(res);
                } catch (err) {
                    setError(err);
                }
            }
        }
        fetchData();
    }, [status]);

    return { response, error };
};
