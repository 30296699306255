import React from "react";

export default function PDFHeader() {
    return (
        <nav className='black'>
            <div className='nav-wrapper'>
                <div className='brand-logo'>
                    <div data-aos='flip-right' data-aos-once='false'>
                        <img style={{height: '50px', marginLeft: "20px",marginTop: "8px"}} src='/logoEron.png' alt='logo' />
                    </div>
                </div>
            </div>
        </nav>
    );
}
