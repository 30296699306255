import React from "react";
import L from "leaflet";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";

export default function PDFMap({ name, position, id }) {
    const mapZoom = 13;
    var houseIcon = new L.Icon({
        iconUrl: "/house.png",
        iconSize: [35, 45],
    });

    return (
        <MapContainer
            center={position}
            zoom={mapZoom}
            // zoomControl={false}
            scrollWheelZoom={false}
            style={{ height: "100%", width: "100%" }}
        >
            <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
            <Marker key={id} position={position} icon={houseIcon}>
                <Tooltip>
                    <p>{name}</p>
                </Tooltip>
            </Marker>
        </MapContainer>
    );
}
