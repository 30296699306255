import React from "react";
import { useParams } from "react-router-dom";
import Preloader from "../components/Preloader";
import PDFHeader from "../components/PDFHeader";
import PDFMap from "../components/PDFMap";

export default function PDFPage(props) {
    const { db, error } = props;

    const { id } = useParams();

    const getFiltered = (id) => {
            const {
                id: houseId,
                name: houseName,
                district: houseDistrict,
                distance: houseDistance,
                houseurls: slides,
                dateofputting: houseDateOfPutting,
                description: houseDescription,
                position,
                infrastructure: houseInfrastructure,
                studioPrice,
                oneBedroomsPrice,
                twoBedroomsPrice,
                threeBedroomPrice,
            } = db.find((i) => i.id === id);

        return (
            <>
                <div className='object_container'>
                    <div className='slider_styles'>
                        <img
                            className='image_styles'
                            src={slides[0]}
                            alt='img'
                        />
                    </div>
                    <div className='object_wrapper'>
                        <div className='object_housename'>{houseName}</div>
                        <div>ID: {houseId}</div>
                        <div className='object_info'>
                            <div className='object_description' key={houseId}>
                                <table className='house_info_container'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p
                                                    style={{
                                                        fontWeight: 800,
                                                    }}
                                                >
                                                    Срок сдачи:
                                                </p>
                                            </td>
                                            <td>
                                                <p
                                                    style={{
                                                        fontWeight: 500,
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    {houseDateOfPutting}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p
                                                    style={{
                                                        fontWeight: 800,
                                                    }}
                                                >
                                                    Район:
                                                </p>
                                            </td>
                                            <td>
                                                <p
                                                    style={{
                                                        fontWeight: 500,
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    {houseDistrict}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p
                                                    style={{
                                                        fontWeight: 800,
                                                    }}
                                                >
                                                    Расстояние до моря:
                                                </p>
                                            </td>
                                            <td>
                                                <p
                                                    style={{
                                                        fontWeight: 500,
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    {houseDistance} м
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <p
                                                    style={{
                                                        fontWeight: 800,
                                                    }}
                                                >
                                                    О доме:
                                                </p>
                                            </td>
                                            <td>
                                                <p
                                                    style={{
                                                        fontWeight: 500,
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    {houseDescription}
                                                </p>
                                            </td>
                                        </tr>
                                        {studioPrice && (
                                            <tr>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        1+0
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: 500,
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        от{" "}
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {studioPrice}
                                                        </span>{" "}
                                                        €
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                        {oneBedroomsPrice && (
                                            <tr>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        1+1
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: 500,
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        от{" "}
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {oneBedroomsPrice}
                                                        </span>{" "}
                                                        €
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                        {twoBedroomsPrice && (
                                            <tr>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        2+1
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: 500,
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        от{" "}
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {twoBedroomsPrice}
                                                        </span>{" "}
                                                        €
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                        {threeBedroomPrice && (
                                            <tr>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        3+1
                                                    </p>
                                                </td>
                                                <td>
                                                    <p
                                                        style={{
                                                            fontWeight: 500,
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        от{" "}
                                                        <span
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        >
                                                            {threeBedroomPrice}
                                                        </span>{" "}
                                                        €
                                                    </p>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                <div className='object_infrastructure'>
                                    <p>
                                        <p
                                            style={{
                                                fontSize: "30px",
                                                fontWeight: 800,
                                            }}
                                        >
                                            Инфраструктурa:
                                        </p>
                                    </p>
                                    <p style={{ fontWeight: 500 }}>
                                        {houseInfrastructure}
                                    </p>
                                </div>
                            </div>
                            <div className='social_contacts_object_wrapper'></div>
                            <div className='object_map'>
                                <PDFMap
                                    houseId={id}
                                    name={houseName}
                                    position={position}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <PDFHeader />
            <div>
                {db ? (
                    getFiltered(id)
                ) : error ? (
                    <div>Sorry, we will fix it soon ...</div>
                ) : (
                    <Preloader />
                )}
            </div>
        </>
    );
}
