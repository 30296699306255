export const schools = [
    {
        id: "1",
        brandName: "",
        coords: [36.48836625386806, 34.17104220321973],
    },
    {
        id: "2",
        brandName: "",
        coords: [36.50585728727152, 34.179925678749264],
    },
    {
        id: "3",
        brandName: "",
        coords: [36.53575883432991, 34.22820544039146],
    },
    {
        id: "4",
        brandName: "",
        coords: [36.53634502364052, 34.228548763117246],
    },
    {
        id: "5",
        brandName: "",
        coords: [36.585040979790676, 34.27239507455033],
    },
    {
        id: "6",
        brandName: "",
        coords: [36.602854674888974, 34.27930444440664],
    },
    {
        id: "7",
        brandName: "",
        coords: [36.603922676791015, 34.27913278304375],
    },
    {
        id: "8",
        brandName: "",
        coords: [36.60474950681802, 34.28050607394687],
    },
    {
        id: "9",
        brandName: "",
        coords: [36.60871127771609, 34.269863069447716],
    },
    {
        id: "10",
        brandName: "",
        coords: [36.609882544918385, 34.274369180223566],
    },
    {
        id: "11",
        brandName: "",
        coords: [36.60936581158068, 34.28732961312172],
    },
    {
        id: "12",
        brandName: "",
        coords: [36.60406048241099, 34.2989596704575],
    },
    {
        id: "13",
        brandName: "",
        coords: [36.60681654311029, 34.29750054887293],
    },
    {
        id: "14",
        brandName: "",
        coords: [36.60450834897565, 34.30968850563809],
    },
    {
        id: "15",
        brandName: "",
        coords: [36.609538056411274, 34.30621236303958],
    },
    {
        id: "16",
        brandName: "",
        coords: [36.6106404142175, 34.308358130075696],
    },
    {
        id: "17",
        brandName: "",
        coords: [36.60674764310961, 34.312606751753464],
    },
    {
        id: "18",
        brandName: "",
        coords: [36.6085390313556, 34.313422143227186],
    },
    {
        id: "19",
        brandName: "",
        coords: [36.609848096453526, 34.31509584151536],
    },
    {
        id: "20",
        brandName: "",
        coords: [36.609985891487064, 34.31767076195871],
    },
    {
        id: "21",
        brandName: "",
        coords: [36.611570516869335, 34.32234853630189],
    },
    {
        id: "22",
        brandName: "",
        coords: [36.612052787638014, 34.32458013401945],
    },
    {
        id: "23",
        brandName: "",
        coords: [36.61511858127845, 34.319859446539986],
    },
    {
        id: "24",
        brandName: "",
        coords: [36.63206436456437, 34.33131784251287],
    },
    {
        id: "25",
        brandName: "",
        coords: [36.6356114858003, 34.35487836456946],
    },
    {
        id: "26",
        brandName: "",
        coords: [36.64707824580393, 34.35062974583794],
    },
    {
        id: "27",
        brandName: "",
        coords: [36.68020130587772, 34.38792049944908],
    },
    {
        id: "28",
        brandName: "",
        coords: [36.67214722731084, 34.407275318114884],
    },
    {
        id: "29",
        brandName: "",
        coords: [36.67266353969006, 34.406459926641155],
    },
    {
        id: "30",
        brandName: "",
        coords: [36.67421245604057, 34.407189487433435],
    },
    {
        id: "31",
        brandName: "",
        coords: [36.67524504961806, 34.410365222646895],
    },
    {
        id: "32",
        brandName: "",
        coords: [36.68470984496074, 34.418647883406315],
    },
    {
        id: "33",
        brandName: "",
        coords: [36.685019582218914, 34.41740333852537],
    },
    {
        id: "34",
        brandName: "",
        coords: [36.68550139325223, 34.420922396464604],
    },
    {
        id: "35",
        brandName: "",
        coords: [36.686430592127216, 34.42371189736297],
    },
    {
        id: "36",
        brandName: "",
        coords: [36.701915580379875, 34.45418178927587],
    },
    {
        id: "37",
        brandName: "",
        coords: [36.717363049454, 34.47542488565473],
    },
    {
        id: "38",
        brandName: "",
        coords: [36.720596647504976, 34.48139011801514],
    },
    {
        id: "39",
        brandName: "",
        coords: [36.72310775208709, 34.49070274695191],
    },
    {
        id: "40",
        brandName: "",
        coords: [36.72393330278439, 34.48890030264157],
    },
    {
        id: "41",
        brandName: "",
        coords: [36.727992131305875, 34.504349825301624],
    },
    {
        id: "42",
        brandName: "",
        coords: [36.73184438027752, 34.5035344338279],
    },
    {
        id: "43",
        brandName: "",
        coords: [36.732147479450596, 34.50962036962684],
    },
    {
        id: "44",
        brandName: "",
        coords: [36.73479577805246, 34.50914830087889],
    },
    {
        id: "45",
        brandName: "",
        coords: [36.739060378592804, 34.521078765599725],
    },
    {
        id: "46",
        brandName: "",
        coords: [36.74329035313051, 34.5260140297828],
    },
    {
        id: "47",
        brandName: "",
        coords: [36.74683234758343, 34.53056305589937],
    },
    {
        id: "48",
        brandName: "",
        coords: [36.74762325605508, 34.53322380702416],
    },
    {
        id: "49",
        brandName: "",
        coords: [36.74817344844491, 34.533824621794274],
    },
    {
        id: "50",
        brandName: "",
        coords: [36.74727938380818, 34.5427080973238],
    },
    {
        id: "51",
        brandName: "",
        coords: [36.74892995653953, 34.54163521380575],
    },
    {
        id: "52",
        brandName: "",
        coords: [36.754534761436744, 34.54511135640426],
    },
    {
        id: "53",
        brandName: "",
        coords: [36.75738858451545, 34.54502552572282],
    },
    {
        id: "54",
        brandName: "",
        coords: [36.75450037737888, 34.53339546838705],
    },
    {
        id: "55",
        brandName: "",
        coords: [36.75467229751403, 34.53103512464732],
    },
    {
        id: "56",
        brandName: "",
        coords: [36.758248148974864, 34.539789854154684],
    },
    {
        id: "57",
        brandName: "",
        coords: [36.758248148974864, 34.539789854154684],
    },
    {
        id: "58",
        brandName: "",
        coords: [36.7592452316805, 34.53575581212678],
    },
    {
        id: "59",
        brandName: "",
        coords: [36.76600875713231, 34.54767166204281],
    },
    {
        id: "60",
        brandName: "",
        coords: [36.77011373937982, 34.55413129879775],
    },
    {
        id: "61",
        brandName: "",
        coords: [36.775172394064306, 34.55283215397552],
    },
    {
        id: "62",
        brandName: "",
        coords: [36.77748481074603, 34.551857795358856],
    },
    {
        id: "63",
        brandName: "",
        coords: [36.78023071495103, 34.55091952409836],
    },
    {
        id: "64",
        brandName: "",
        coords: [36.78099087385022, 34.54914440463216],
    },
    {
        id: "65",
        brandName: "",
        coords: [36.77229421673693, 34.56214775287104],
    },
    {
        id: "66",
        brandName: "",
        coords: [36.78013156431234, 34.557856218798804],
    },
    {
        id: "67",
        brandName: "",
        coords: [36.776831726157035, 34.56940044545313],
    },
    {
        id: "68",
        brandName: "",
        coords: [36.778962887879544, 34.56669677898762],
    },
    {
        id: "69",
        brandName: "",
        coords: [36.77741608284802, 34.57867015904917],
    },
    {
        id: "70",
        brandName: "",
        coords: [36.77841291633344, 34.57815517496049],
    },
    {
        id: "71",
        brandName: "",
        coords: [36.78095650165369, 34.579099312456385],
    },
    {
        id: "72",
        brandName: "",
        coords: [36.78487483276815, 34.57742561416822],
    },
    {
        id: "73",
        brandName: "",
        coords: [36.78353437361628, 34.58176006358118],
    },
    {
        id: "74",
        brandName: "",
        coords: [36.784256162227, 34.583004608462126],
    },
    {
        id: "75",
        brandName: "",
        coords: [36.780440916855866, 34.58781112662304],
    },
    {
        id: "76",
        brandName: "",
        coords: [36.78666207516349, 34.58905567150398],
    },
    {
        id: "77",
        brandName: "",
        coords: [36.78755568095234, 34.59253181765706],
    },
    {
        id: "78",
        brandName: "",
        coords: [36.78800247982725, 34.59699501309218],
    },
    {
        id: "79",
        brandName: "",
        coords: [36.78432490386795, 34.59939827217264],
    },
    {
        id: "80",
        brandName: "",
        coords: [36.788930438396456, 34.601801531253095],
    },
    {
        id: "81",
        brandName: "",
        coords: [36.78525290697418, 34.61836685277194],
    },
    {
        id: "82",
        brandName: "",
        coords: [36.786352747954915, 34.61416114938115],
    },
    {
        id: "83",
        brandName: "",
        coords: [36.79260779331599, 34.61506237153632],
    },
    {
        id: "84",
        brandName: "",
        coords: [36.80044306361264, 34.63334430668406],
    },
    {
        id: "85",
        brandName: "",
        coords: [36.799893246231655, 34.62840904250098],
    },
    {
        id: "86",
        brandName: "",
        coords: [36.80092415058315, 34.62956775670048],
    },
    {
        id: "87",
        brandName: "",
        coords: [36.80271101858712, 34.629224433974706],
    },
    {
        id: "88",
        brandName: "",
        coords: [36.80377624698424, 34.62810863511592],
    },
    {
        id: "89",
        brandName: "",
        coords: [36.80848369181378, 34.638966216318686],
    },
    {
        id: "90",
        brandName: "",
        coords: [36.80996115132094, 34.641412390739866],
    },
    {
        id: "91",
        brandName: "",
        coords: [36.810064228827244, 34.63347305270623],
    },
    {
        id: "92",
        brandName: "",
        coords: [36.81082012629973, 34.632958068617555],
    },
    {
        id: "93",
        brandName: "",
        coords: [36.81295034265946, 34.62935317999687],
    },
    {
        id: "94",
        brandName: "",
        coords: [36.81453078746127, 34.630640640218544],
    },
    {
        id: "95",
        brandName: "",
        coords: [36.818825310206456, 34.65587486447642],
    },
    {
        id: "96",
        brandName: "",
        coords: [36.82208898589242, 34.66707576840496],
    },
    {
        id: "97",
        brandName: "",
        coords: [36.821401907840446, 34.648750917916495],
    },
    {
        id: "98",
        brandName: "",
        coords: [36.82226075444157, 34.64866508723505],
    },
    {
        id: "99",
        brandName: "",
        coords: [36.821985924577895, 34.6470343042876],
    },
    {
        id: "100",
        brandName: "",
        coords: [36.8251120560368, 34.64184154806019],
    },
    {
        id: "101",
        brandName: "",
        coords: [36.82878767361574, 34.64694847360616],
    },
    {
        id: "102",
        brandName: "",
        coords: [36.82950903577711, 34.64617599747315],
    },
    {
        id: "103",
        brandName: "",
        coords: [36.82950903577711, 34.64617599747315],
    },
    {
        id: "104",
        brandName: "",
        coords: [36.82720752321018, 34.67085231838853],
    },
    {
        id: "105",
        brandName: "",
        coords: [36.82796325138876, 34.67128147179575],
    },
    {
        id: "106",
        brandName: "",
        coords: [36.83181047911087, 34.68243946038358],
    },
    {
        id: "107",
        brandName: "",
        coords: [36.83407750485162, 34.67656005870461],
    },
    {
        id: "108",
        brandName: "",
        coords: [36.834043156478025, 34.68784679331459],
    },
    {
        id: "109",
        brandName: "",
        coords: [36.83552012261516, 34.68278278310935],
    },
    {
        id: "110",
        brandName: "",
        coords: [36.8138436421485, 34.686001433663534],
    },
    {
        id: "111",
        brandName: "",
        coords: [36.83354607205844, 34.69382932797596],
    },
    {
        id: "112",
        brandName: "",
        coords: [36.83056413878438, 34.70299015149707],
    },
    {
        id: "113",
        brandName: "",
        coords: [36.830727535398466, 34.702607387283095],
    },
    {
        id: "114",
        brandName: "",
        coords: [36.845411380874296, 34.708119191964315],
    },
    {
        id: "115",
        brandName: "",
        coords: [36.8452684363024, 34.709165414149176],
    },
    {
        id: "116",
        brandName: "",
        coords: [36.84394108108759, 34.714779289287456],
    },
    {
        id: "117",
        brandName: "",
        coords: [36.92118110442815, 34.85513381126722],
    },
    {
        id: "118",
        brandName: "",
        coords: [36.9138527266219, 34.86877483190056],
    },
    {
        id: "119",
        brandName: "",
        coords: [36.917084224107064, 34.878951466341306],
    },
    {
        id: "120",
        brandName: "",
        coords: [36.92400840050607, 34.872455742230194],
    },
    {
        id: "121",
        brandName: "",
        coords: [36.92643171370177, 34.866826114667234],
    },
    {
        id: "122",
        brandName: "",
        coords: [36.92983576166082, 34.862206933077104],
    },
    {
        id: "123",
        brandName: "",
        coords: [36.9126408797657, 34.89035507089193],
    },
    {
        id: "124",
        brandName: "",
        coords: [36.9126408797657, 34.89035507089193],
    },
    {
        id: "125",
        brandName: "",
        coords: [36.9159301335802, 34.89750036741415],
    },
    {
        id: "126",
        brandName: "",
        coords: [36.91067880117715, 34.9058004593339],
    },
    {
        id: "127",
        brandName: "",
        coords: [36.92325831176395, 34.89259248697464],
    },
    {
        id: "128",
        brandName: "",
        coords: [36.922912114471046, 34.88097235828698],
    },
    {
        id: "129",
        brandName: "",
        coords: [36.926200925287404, 34.875126206586984],
    },
    {
        id: "130",
        brandName: "",
        coords: [36.934855012676955, 34.88256020195859],
    },
    {
        id: "131",
        brandName: "",
        coords: [36.92747025292073, 34.92355543857094],
    },
    {
        id: "132",
        brandName: "",
        coords: [36.93277812130935, 34.92413283626972],
    },
    {
        id: "133",
        brandName: "",
        coords: [36.93168196138191, 34.91792581100799],
    },
    {
        id: "134",
        brandName: "",
        coords: [36.95804312146412, 34.90897614667712],
    },
    // {
    //     id: "135",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "136",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "137",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "138",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "139",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "140",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "141",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "142",
    //     brandName: "",
    //     coords: [],
    // },
];
