export const hospitals = [
    // {
    //     id: "1",
    //     brandName: "",
    //     coords: [36.611497864675925, 34.3015847489744],
    // },
    // {
    //     id: "2",
    //     brandName: "",
    //     coords: [36.7597195139504, 34.54465969236931],
    // },
    // {
    //     id: "3",
    //     brandName: "",
    //     coords: [36.76455914470551, 34.548336839585126],
    // },
    // {
    //     id: "4",
    //     brandName: "",
    //     coords: [36.75395781448443, 34.53628344745086],
    // },
    // {
    //     id: "5",
    //     brandName: "",
    //     coords: [36.77207629927423, 34.562698815271716],
    // },
    // {
    //     id: "6",
    //     brandName: "",
    //     coords: [36.790968752119035, 34.58796569093118],
    // },
    // {
    //     id: "7",
    //     brandName: "",
    //     coords: [36.79273747021538, 34.61544120923309],
    // },
    // {
    //     id: "8",
    //     brandName: "",
    //     coords: [36.807097946859955, 34.634523885147885],
    // },
    // {
    //     id: "9",
    //     brandName: "",
    //     coords: [36.91444738758537, 34.88211121673245],
    // },
    // {
    //     id: "10",
    //     brandName: "",
    //     coords: [36.91440158622207, 34.90439512326095],
    // },
    // {
    //     id: "11",
    //     brandName: "",
    //     coords: [36.91626795609796, 34.914072466384404],
    // },
    // {
    //     id: "12",
    //     brandName: "",
    //     coords: [36.38352601189662, 34.02951948539326],
    // },
    // {
    //     id: "13",
    //     brandName: "",
    //     coords: [36.79135817950911, 34.62232316903472],
    // },
    // {
    //     id: "14",
    //     brandName: "",
    //     coords: [36.8568265984139, 34.60941175759187],
    // },
    {
        id: "15",
        brandName: "Центр семейного здоровья Кизкалеси",
        coords: [36.46161526336252, 34.1441184416852],
    },
    {
        id: "16",
        brandName: "Государственная больница Эрдемли",
        coords: [36.610368229281214, 34.30183748514405],
    },
    {
        id: "17",
        brandName: "ОТДЕЛЕНИЕ СПЕЦИАЛЬНЫХ ДЕТСКИХ БОЛЕЗНЕЙ ЦЕНТРА МЕРСИН",
        coords: [36.60870906944461, 34.30433821136286],
    },
    {
        id: "18",
        brandName: "Общественный центр здоровья Эрдемли",
        coords: [36.60444834733916, 34.313269376430036],
    },
    {
        id: "19",
        brandName: "Центр семейного здоровья Каргипинари",
        coords: [36.67800715469306, 34.40913022625823],
    },
    {
        id: "20",
        brandName: "Больница VM Medical Park в Мерсине",
        coords: [36.7593927206819, 34.54457569476508],
    },
    {
        id: "21",
        brandName: "Торосская государственная больница Районная поликлиника",
        coords: [36.75666837588745, 34.537898167094355],
    },
    {
        id: "22",
        brandName: "Городская больница",
        coords: [36.753048830209146, 34.53647983887072],
    },
    {
        id: "23",
        brandName:
            "Мезитлинская районная поликлиника, отделение акушерства и гинекологии",
        coords: [36.75780470965464, 34.536795022920415],
    },
    {
        id: "24",
        brandName: "Медицинская поликлиника Догус",
        coords: [36.74689959455784, 34.52682177350501],
    },
    {
        id: "25",
        brandName: "Больница Форум ",
        coords: [36.78399470595344, 34.595443407767185],
    },
    {
        id: "26",
        brandName:
            "Медицинская онкологическая поликлиника и отделение амбулаторного лечения государственной больницы Мерсина",
        coords: [36.808819000131486, 34.623000428713596],
    },
    {
        id: "27",
        brandName: "Центр системной хирургии Мерсина",
        coords: [36.79069550000007, 34.622140397422974],
    },
    {
        id: "28",
        brandName: "Государственная больница Таурус Мерсин",
        coords: [36.80630280431351, 34.63450778191723],
    },
    {
        id: "29",
        brandName: "Поликлиника государственной больницы Мерсина",
        coords: [36.8108429686378, 34.62178706484118],
    },
    {
        id: "30",
        brandName: "Больница кожных и венерических болезней",
        coords: [36.8240699622969, 34.6541881473567],
    },
    {
        id: "31",
        brandName: "Торосларская районная поликлиника",
        coords: [36.82403667070632, 34.60288166802932],
    },
    {
        id: "32",
        brandName: "Государственная больница Мерсин Тарсус",
        coords: [36.914966734346706, 34.905190283732466],
    },
    {
        id: "33",
        brandName: "Больница акушерства и гинекологии Тарсус",
        coords: [36.916215158925844, 34.898449525283496],
    },
    {
        id: "34",
        brandName:
            "Центр здоровья матери и ребенка и планирования семьи Tarsus",
        coords: [36.92018511131352, 34.90039001027938],
    },
    {
        id: "35",
        brandName: "Глазной центр майя Тарсус",
        coords: [36.915269265318656, 34.88358457779503],
    },
    {
        id: "36",
        brandName: "Городская больница Мерсина",
        coords: [36.85660475690459, 34.609365243500044],
    },
    // {
    //     id: "37",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "38",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "39",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "40",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "41",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "42",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "43",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "44",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "45",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "46",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "47",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "48",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "49",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "50",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "51",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "52",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "53",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "54",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "55",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "56",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "57",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "58",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "59",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "60",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "61",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "62",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "63",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "64",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "65",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "66",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "67",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "68",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "69",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "70",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "71",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "72",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "73",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "74",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "75",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "76",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "77",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "78",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "79",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "80",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "81",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "82",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "83",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "84",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "85",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "86",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "87",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "88",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "89",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "90",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "91",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "92",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "93",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "94",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "95",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "96",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "97",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "98",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "99",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "100",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "101",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "102",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "103",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "104",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "105",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "106",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "107",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "108",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "109",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "110",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "111",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "112",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "113",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "114",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "115",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "116",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "117",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "118",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "119",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "120",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "121",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "122",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "123",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "124",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "125",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "126",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "127",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "128",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "129",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "130",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "131",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "132",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "133",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "134",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "135",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "136",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "137",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "138",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "139",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "140",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "141",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "142",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "143",
    //     brandName: "",
    //     coords: [],
    // },
    // {
    //     id: "144",
    //     brandName: "",
    //     coords: [],
    // },
];
