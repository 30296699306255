import React, { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

export default function WSPGallery({ slides }) {
    const [slideNumber, setSlideNumber] = useState(0);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = (index) => {
        setSlideNumber(index);
        setOpenModal(true);
    };

    const prevSlide = () => {
        slideNumber === 0
            ? setSlideNumber(slides.length - 1)
            : setSlideNumber(slideNumber - 1);
    };

    const nextSlide = () => {
      slideNumber + 1 === slides.length ? setSlideNumber(0) : setSlideNumber(slideNumber + 1);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    return (
        <div>
            {openModal && (
                <div className='sliderWrap'>
                    <ChevronLeftIcon className='btnPrev' onClick={prevSlide} />
                    <ChevronRightIcon className='btnNext' onClick={nextSlide} />
                    <CloseIcon
                        className='btnClose'
                        onClick={handleCloseModal}
                    />
                    <div className='fullScreenImage'>
                        <img src={slides[slideNumber]} alt='img' />
                    </div>
                </div>
            )}
            <div className='galleryWrap'>
                {slides &&
                    slides.map((slide, index) => {
                        return (
                            <div
                                className='single'
                                key={index}
                                onClick={() => handleOpenModal(index)}
                            >
                                <img src={slide} alt='img' />
                            </div>
                        );
                    })}
            </div>
        </div>
    );
}
