import { Navigate } from "react-router-dom";

const PublicRoute = ({ children }) => {
    const user = localStorage.getItem("user1");
    if (user) {
        return <Navigate to='/' />;
    } else {
        return children;
    }
};

export default PublicRoute;
