/**
 * Import necessary modules and libraries.
 */
import { Link, useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { ToastContainer, toast } from "react-toastify";
import "./buttonsBlock.css";

/**
 * Define the ButtonsBlock component.
 * @param {Object} props - The props object containing the necessary data for rendering buttons.
 */
const ButtonsBlock = ({
    streetView,
    linkToPrice,
    itemId,
    distance,
    district,
    name,
    dateOfPutting,
    roomsQuantity,
    minPrice,
    slides,
    pdf,
}) => {
    /**
     * Create an object with the necessary data.
     */
    const obj = {
        id: itemId,
        district: district,
        name: name,
        image: slides[0],
        date: dateOfPutting,
        roomsQuantity: roomsQuantity,
        distance,
        minPrice,
        pdf,
    };

    /**
     * Use the useNavigate hook from the react-router-dom package.
     */
    const navigate = useNavigate();

    /**
     * Add the object to local storage as a favorite.
     */
    const addToLocalStorage = () => {
        if (localStorage.getItem("favorites")) {
            let favorites = JSON.parse(localStorage.getItem("favorites"));
            if (favorites.find((f) => f.id === obj.id)) {
                toast.warning("Объект уже в избранном.");
                localStorage.setItem("favorites", JSON.stringify(favorites));
            } else {
                favorites.find((f) => f.id !== obj.id);
                favorites.push(obj);
                toast.success("Объект сохранен в избранном.");
                localStorage.setItem("favorites", JSON.stringify(favorites));
            }
        } else {
            let favorites = [];
            favorites.push(obj);
            localStorage.setItem("favorites", JSON.stringify(favorites));
        }
    };

    /**
     * Render the ButtonsBlock component.
     */
    return (
        <>
            <ToastContainer autoClose={2000} />
            <div className='buttons_container'>
                <button className='btn style_btn' onClick={() => navigate(-1)}>
                    НАЗАД
                </button>
                <Link to={"/"}>
                    <button className='btn style_btn'>КАРТА</button>
                </Link>
                <a href={streetView} target='_blank' rel='noreferrer'>
                    <button className='btn style_btn'>РАЙОН</button>
                </a>
                <a href={linkToPrice} target='_blank' rel='noreferrer'>
                    <button className='btn style_btn'>ПРАЙС</button>
                </a>
                <button
                    className='btn style_btn favorites_btn'
                    onClick={addToLocalStorage}
                >
                    <FavoriteIcon />
                </button>
            </div>
        </>
    );
};

export default ButtonsBlock;
