import "./informationPage.css";
import { useParams } from "react-router-dom";
import ButtonsBlock from "../../components/ButtonsBlock/ButtonsBlock";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import MenuButtonsBlock from "../../components/MenuButtonsBlock/MenuButtonsBlock";
import Preloader from "../../components/Preloader";

const InformationPage = (props) => {
    const { db, error } = props;
    const { id } = useParams();

    const containerStyles = {
        maxWidth: "600px",
        maxHeight: "650px",
        margin: 0,
    };

    const getFiltered = (id) => {
        const filteredDB = db.find((i) => i.id === id);

        const name = filteredDB.name;
        const itemId = filteredDB.id;
        const streetView = filteredDB.streetview;
        const slides = filteredDB.houseurls;
        const houseId = filteredDB.id;
        const linkToPrice = filteredDB.price;
        const dateOfPutting = filteredDB.dateofputting;
        const roomsQuantity = filteredDB.roomsQuantity;
        const district = filteredDB.district;
        const minPrice = filteredDB.minPrice;
        const distance = filteredDB.distance;
        const description = filteredDB.description;
        const conditions = filteredDB.conditions;
        const pdf = filteredDB.pdf;

        return (
            <>
                <MenuButtonsBlock houseId={houseId} />
                <div className='info_container'>
                    <div className='block'>
                        <div style={containerStyles}>
                            <div className='residental_name' key={itemId}>
                                {name}
                            </div>
                            <div data-aos='zoom-in'>
                                <div style={containerStyles}>
                                    <ImageSlider slides={slides} />
                                </div>
                            </div>
                            <ButtonsBlock
                                streetView={streetView}
                                linkToPrice={linkToPrice}
                                distance={distance}
                                minPrice={minPrice}
                                district={district}
                                roomsQuantity={roomsQuantity}
                                dateOfPutting={dateOfPutting}
                                name={name}
                                itemId={itemId}
                                slides={slides}
                                pdf={pdf}
                            />
                        </div>
                        <div className='information'>
                            <table className='centered'>
                                <tbody>
                                    <tr>
                                        <td>
                                            <p style={{ fontWeight: 800 }}>
                                                СРОК СДАЧИ
                                            </p>
                                        </td>
                                        <td>
                                            <p style={{ fontWeight: 500 }}>
                                                {dateOfPutting}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ fontWeight: 800 }}>
                                                РАЙОН
                                            </p>
                                        </td>
                                        <td>
                                            <p style={{ fontWeight: 500 }}>
                                                {district}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ fontWeight: 800 }}>
                                                ДО МОРЯ
                                            </p>
                                        </td>
                                        <td>
                                            <p style={{ fontWeight: 500 }}>
                                                {distance} м
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ fontWeight: 800 }}>
                                                УСЛОВИЯ
                                            </p>
                                        </td>
                                        <td>
                                            <p style={{ fontWeight: 500 }}>
                                                {conditions}
                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{ fontWeight: 800 }}>
                                                О ДОМЕ
                                            </p>
                                        </td>
                                        <td>
                                            <p style={{ fontWeight: 500 }}>
                                                {description}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    };
    return (
        <>
            {db ? (
                getFiltered(id)
            ) : error ? (
                <div>Sorry, we will fix it soon ...</div>
            ) : (
                <Preloader />
            )}
        </>
    );
};

export default InformationPage;
