import "./mapInfrastructure.css";
import { useEffect, useState } from "react";
import L from "leaflet";
import { Marker, Tooltip } from "react-leaflet";
import { shops } from "../../DBs/shopsDB";
import { schools } from "../../DBs/schoolsDB";
import { pharmacies } from "../../DBs/pharmaciesDB";
import { hospitals } from "../../DBs/hospitalsDB";

const MapInfrastructure = () => {
    const [hospitalState, setHospitalState] = useState(false);
    const [shopState, setShopState] = useState(false);
    const [schoolState, setSchoolState] = useState(false);
    const [pharmacyState, setPharmacyState] = useState(false);
    const [shopButtonColor, setShopButtonColor] = useState("#000000");
    const [schoolButtonColor, setSchoolButtonColor] = useState("#000000");
    const [pharmacyButtonColor, setPharmacyButtonColor] = useState("#000000");
    const [hospitalButtonColor, setHospitalButtonColor] = useState("#000000");

    var portIcon = new L.Icon({
        iconUrl: "infrastructureImages/seaPort.svg",
        iconSize: [40, 40],
    });

    const portCoords = [36.805450042560146, 34.649657733924094];

    var airPortTarsusIcon = new L.Icon({
        iconUrl: "infrastructureImages/airportIcon.svg",
        iconSize: [40, 40],
    });

    const airPortTarsusCoords = [36.90611649334676, 35.06530963966281];

    var airPortAdanaIcon = new L.Icon({
        iconUrl: "infrastructureImages/airportIcon.svg",
        iconSize: [40, 40],
    });

    const airPortAdanaCoords = [36.98845595063763, 35.29667724995522];

    var hospitalIcon = new L.Icon({
        iconUrl: "infrastructureImages/medicine.png",
        iconSize: [30, 30],
    });

    var shopIcon = new L.Icon({
        iconUrl: "infrastructureImages/shop.png",
        iconSize: [30, 30],
    });

    var schoolIcon = new L.Icon({
        iconUrl: "infrastructureImages/school.png",
        iconSize: [30, 30],
    });

    var pharmacyIcon = new L.Icon({
        iconUrl: "infrastructureImages/pharmacy.png",
        iconSize: [30, 30],
    });

    const handleHospitalState = () => setHospitalState(!hospitalState);

    const handleSchoolState = () => setSchoolState(!schoolState);

    const handleShopState = () => setShopState(!shopState);

    const handlePharmacyState = () => setPharmacyState(!pharmacyState);

    useEffect(() => {
        shopState === true
            ? setShopButtonColor("#808080")
            : setShopButtonColor("#000000");

        schoolState === true
            ? setSchoolButtonColor("#808080")
            : setSchoolButtonColor("#000000");

        pharmacyState === true
            ? setPharmacyButtonColor("#808080")
            : setPharmacyButtonColor("#000000");

        hospitalState === true
            ? setHospitalButtonColor("#808080")
            : setHospitalButtonColor("#000000");
    }, [shopState, schoolState, pharmacyState, hospitalState]);

    return (
        <div>
            <div className='buttons_infrastructure_container'>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        handlePharmacyState();
                    }}
                    className='style_btn infrastructure_btn'
                    style={{
                        background: pharmacyButtonColor,
                        marginRight: "5px",
                    }}
                >
                    Аптеки
                </button>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        handleHospitalState();
                    }}
                    className='style_btn infrastructure_btn'
                    style={{
                        background: hospitalButtonColor,
                        marginRight: "5px",
                    }}
                >
                    Больницы
                </button>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        handleShopState();
                    }}
                    className='style_btn infrastructure_btn'
                    style={{
                        background: shopButtonColor,
                        marginRight: "5px",
                    }}
                >
                    Магазины
                </button>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        handleSchoolState();
                    }}
                    className='style_btn infrastructure_btn'
                    style={{
                        background: schoolButtonColor,
                        marginRight: "5px",
                    }}
                >
                    Школы
                </button>
            </div>
            {shopState &&
                shops.map((shop) => {
                    return (
                        <Marker
                            id={shop.id}
                            position={shop.coords}
                            icon={shopIcon}
                        >
                            <Tooltip>
                                <p>{shop.brandName}</p>
                            </Tooltip>
                        </Marker>
                    );
                })}
            {schoolState &&
                schools.map((school) => {
                    return (
                        <Marker
                            id={school.id}
                            position={school.coords}
                            icon={schoolIcon}
                        ></Marker>
                    );
                })}
            {pharmacyState &&
                pharmacies.map((pharmacy) => {
                    return (
                        <Marker
                            id={pharmacy.id}
                            position={pharmacy.coords}
                            icon={pharmacyIcon}
                        ></Marker>
                    );
                })}
            {hospitalState &&
                hospitals.map((hospital) => {
                    return (
                        <Marker
                            id={hospital.id}
                            position={hospital.coords}
                            icon={hospitalIcon}
                        >
                            <Tooltip>
                                <p>{hospital.brandName}</p>
                            </Tooltip>
                        </Marker>
                    );
                })}
            <Marker position={portCoords} icon={portIcon}>
                <Tooltip>
                    <p>Морской порт</p>
                </Tooltip>
            </Marker>
            <Marker position={airPortTarsusCoords} icon={airPortTarsusIcon}>
                <Tooltip>
                    <p>Аэропорт Тарсус</p>
                </Tooltip>
            </Marker>
            <Marker position={airPortAdanaCoords} icon={airPortAdanaIcon}>
                <Tooltip>
                    <p>Аэропорт Адана</p>
                </Tooltip>
            </Marker>
        </div>
    );
};

export default MapInfrastructure;
