import "./menuButton.css";
import { Link, useLocation } from "react-router-dom";

const MenuButton = (props) => {
    const { id, name, link, houseId } = props;

    const location = useLocation();

    return (
        <>
            {location.pathname.includes(link) ? (
                <Link to={link + houseId}>
                    <div
                        className='menu_button'
                        id={id}
                        style={{
                            borderBottom: "4px solid red",
                        }}
                    >
                        {name}
                    </div>
                </Link>
            ) : (
                <Link to={link + houseId}>
                    <div
                        className='menu_button'
                        id={id}
                    >
                        {name}
                    </div>
                </Link>
            )}
        </>
    );
};

export default MenuButton;
