import "./markerItem.css";
import { useState } from "react";
import { Marker, Popup, Tooltip } from "react-leaflet";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import L from "leaflet";

const MarkerItem = (props) => {
    const {
        id,
        name,
        pdf,
        position,
        district,
        developername,
        distance,
        dateofputting,
        houseurls,
        price,
        minPrice,
        studioPrice,
        oneBedroomsPrice,
        twoBedroomsPrice,
        threeBedroomPrice,
        docs
    } = props;

    const [showModal, setShowModal] = useState(false);

    var houseIcon = new L.Icon({
        iconUrl: "houseImages/house3.png",
        iconSize: [35, 45],
    });

    var houseSaled = new L.Icon({
        iconUrl: "houseImages/house1.png",
        iconSize: [35, 45],
    });

    // var eronIcon = new L.Icon({
    //     iconUrl: "houseImages/eronIcon.png",
    //     iconSize: [40, 55],
    // });

        var secondHandIcon = new L.Icon({
            iconUrl: "houseImages/house5.png",
            iconSize: [35, 45],
        });

    const customStyles = {
        content: {
            top: "0",
            left: "0",
            right: "auto",
            bottom: "auto",
            minWidth: "350px",
            maxWidth: "650px",
            height: "95%",
            backgroundColor: "#f4f4f5",
            zindex: "2",
        },
    };
    return (
        <>
            <Modal
                onRequestClose={() => setShowModal(false)}
                ariaHideApp={false}
                isOpen={showModal}
                style={customStyles}
                overlayClassName='overlay'
            >
                <h3 className='modal-title'>{name}</h3>
                <div className='modal_block'>
                    <img src={houseurls[0]} alt={name} />
                    <table className='centered'>
                        <tbody>
                            <tr>
                                <td>
                                    <p style={{ fontWeight: 800 }}>ЖК</p>
                                </td>
                                <td>
                                    <p style={{ fontWeight: 500 }}>{name}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p style={{ fontWeight: 800 }}>
                                        ЗАСТРОЙЩИК
                                    </p>
                                </td>
                                <td>
                                    <p style={{ fontWeight: 500 }}>
                                        {developername}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p style={{ fontWeight: 800 }}>
                                        СРОК СДАЧИ
                                    </p>
                                </td>
                                <td>
                                    <p style={{ fontWeight: 500 }}>
                                        {dateofputting}
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p style={{ fontWeight: 800 }}>РАЙОН</p>
                                </td>
                                <td>
                                    <p style={{ fontWeight: 500 }}>
                                        {district}
                                    </p>
                                </td>
                            </tr>
                            {studioPrice && (
                                <tr>
                                    <td>
                                        <p style={{ fontWeight: 800 }}>1+0</p>
                                    </td>
                                    <td>
                                        <p style={{ fontWeight: 500 }}>
                                            от {studioPrice} €
                                        </p>
                                    </td>
                                </tr>
                            )}
                            {oneBedroomsPrice && (
                                <tr>
                                    <td>
                                        <p style={{ fontWeight: 800 }}>1+1</p>
                                    </td>
                                    <td>
                                        <p style={{ fontWeight: 500 }}>
                                            от {oneBedroomsPrice} €
                                        </p>
                                    </td>
                                </tr>
                            )}
                            {twoBedroomsPrice && (
                                <tr>
                                    <td>
                                        <p style={{ fontWeight: 800 }}>2+1</p>
                                    </td>
                                    <td>
                                        <p style={{ fontWeight: 500 }}>
                                            от {twoBedroomsPrice} €
                                        </p>
                                    </td>
                                </tr>
                            )}
                            {threeBedroomPrice && (
                                <tr>
                                    <td>
                                        <p style={{ fontWeight: 800 }}>3+1</p>
                                    </td>
                                    <td>
                                        <p style={{ fontWeight: 500 }}>
                                            от {threeBedroomPrice} €
                                        </p>
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>
                                    <p style={{ fontWeight: 800 }}>ДО МОРЯ</p>
                                </td>
                                <td>
                                    <p style={{ fontWeight: 500 }}>
                                        {distance} м
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='item_buttons_container'>
                    <a href={pdf} download>
                        <img
                            alt='download pdf'
                            style={{ width: "35px", height: "auto" }}
                            src='pdf.png'
                        />
                    </a>
                    {docs && (
                        <a href={docs} target='_blank' rel='noreferrer'>
                            <img
                                alt='download pdf'
                                style={{ width: "35px", height: "auto" }}
                                src='google.png'
                            />
                        </a>
                    )}
                    <Link to={`/houses/${id}`}>
                        <button className='style_btn marker_item_btn'>
                            Подробнее
                        </button>
                    </Link>

                    <a href={price} target='_blank' rel='noreferrer'>
                        <button className='style_btn marker_item_btn'>
                            В наличии
                        </button>
                    </a>
                </div>
            </Modal>
            {minPrice === "" && developername !== "ERON YAPI" ? (
                <Marker key={id} position={position} icon={houseSaled}>
                    <Popup>
                        <p
                            className='popup'
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            {name}
                        </p>
                    </Popup>
                    <Tooltip>
                        <div
                            style={{
                                width: "140px",
                                height: "130px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                style={{
                                    height: "auto",
                                    width: "100px",
                                    margin: "0 auto",
                                }}
                                src={houseurls[0]}
                                alt={name}
                            />
                            <p>{name}</p>
                            <b>Стоимость от: {minPrice} €</b>
                        </div>
                    </Tooltip>
                </Marker>
            )
            //  : developername === "ERON YAPI" ? (
            //     <Marker key={id} position={position} icon={eronIcon}>
            //         <Popup>
            //             <p
            //                 className='popup'
            //                 onClick={() => {
            //                     setShowModal(true);
            //                 }}
            //             >
            //                 {name}
            //             </p>
            //         </Popup>
            //         <Tooltip>
            //             <div
            //                 className='object_prewiew'
            //                 style={{
            //                     width: "140px",
            //                     height: "130px",
            //                     display: "flex",
            //                     flexDirection: "column",
            //                     justifyContent: "center",
            //                     alignItems: "center",
            //                 }}
            //             >
            //                 <img
            //                     style={{
            //                         height: "auto",
            //                         width: "100px",
            //                         margin: "0 auto",
            //                     }}
            //                     src={houseurls[0]}
            //                     alt={name}
            //                 />
            //                 <p>{name}</p>
            //                 <b>Стоимость от: {minPrice} €</b>
            //             </div>
            //         </Tooltip>
            //     </Marker>
            // )
             : developername === "Вторичка" ? (
                <Marker key={id} position={position} icon={secondHandIcon}>
                    <Popup>
                        <p
                            className='popup'
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            {name}
                        </p>
                    </Popup>
                    <Tooltip>
                        <div
                            style={{
                                width: "140px",
                                height: "160px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                style={{
                                    height: "auto",
                                    width: "100px",
                                    margin: "0 auto",
                                }}
                                src={houseurls[0]}
                                alt={name}
                            />
                            <p>{name}</p>
                            <b>Стоимость от: {minPrice} €</b>
                        </div>
                    </Tooltip>
                </Marker>
            ) : (
                <Marker key={id} position={position} icon={houseIcon}>
                    <Popup>
                        <p
                            className='popup'
                            onClick={() => {
                                setShowModal(true);
                            }}
                        >
                            {name}
                        </p>
                    </Popup>
                    <Tooltip>
                        <div
                            style={{
                                width: "140px",
                                height: "160px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img
                                style={{
                                    height: "auto",
                                    width: "100px",
                                    margin: "0 auto",
                                }}
                                src={houseurls[0]}
                                alt={name}
                            />
                            <p>{name}</p>
                            <b>Стоимость от: {minPrice} €</b>
                        </div>
                    </Tooltip>
                </Marker>
            )}
        </>
    );
};

export default MarkerItem;
