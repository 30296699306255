export const pharmacies = [
    {
        id: "1",
        brandName: "",
        coords: [36.4616792615966, 34.14435943032375],
    },
    {
        id: "2",
        brandName: "",
        coords: [36.46250016355874, 34.14387459565272],
    },
    {
        id: "3",
        brandName: "",
        coords: [36.497811238705935, 34.18355448824713],
    },
    {
        id: "4",
        brandName: "",
        coords: [36.53890913366299, 34.23301120171681],
    },
    {
        id: "5",
        brandName: "",
        coords: [36.53984010020991, 34.23339743978332],
    },
    {
        id: "6",
        brandName: "",
        coords: [36.54011593999705, 34.23404116989415],
    },
    {
        id: "7",
        brandName: "",
        coords: [36.55576823524626, 34.23210997956164],
    },
    {
        id: "8",
        brandName: "",
        coords: [36.55745739037465, 34.245156243141246],
    },
    {
        id: "9",
        brandName: "",
        coords: [36.57951643439816, 34.26742930857286],
    },
    {
        id: "10",
        brandName: "",
        coords: [36.58368626408014, 34.27137751991932],
    },
    {
        id: "11",
        brandName: "",
        coords: [36.58551264747099, 34.27523990058433],
    },
    {
        id: "12",
        brandName: "",
        coords: [36.59419599238053, 34.287041619282995],
    },
    {
        id: "13",
        brandName: "",
        coords: [36.6024304842405, 34.30154700444716],
    },
    {
        id: "14",
        brandName: "",
        coords: [36.606185671975425, 34.29450888856869],
    },
    {
        id: "15",
        brandName: "",
        coords: [36.60646127488332, 34.300431205588374],
    },
    {
        id: "16",
        brandName: "",
        coords: [36.6047042900768, 34.3144645244341],
    },
    {
        id: "17",
        brandName: "",
        coords: [36.60542775950422, 34.313992455686154],
    },
    {
        id: "18",
        brandName: "",
        coords: [36.606185672578654, 34.31352038693821],
    },
    {
        id: "19",
        brandName: "",
        coords: [36.60566891447207, 34.31201835001293],
    },
    {
        id: "20",
        brandName: "",
        coords: [36.60780482556012, 34.31214709603508],
    },
    {
        id: "21",
        brandName: "",
        coords: [36.6070813784287, 34.30957217559175],
    },
    {
        id: "22",
        brandName: "",
        coords: [36.60966508702418, 34.30352111254989],
    },
    {
        id: "23",
        brandName: "",
        coords: [36.610351099488106, 34.30305272535614],
    },
    {
        id: "24",
        brandName: "",
        coords: [36.610566402715534, 34.30284887748771],
    },
    {
        id: "25",
        brandName: "",
        coords: [36.61080754161663, 34.30273086030073],
    },
    {
        id: "26",
        brandName: "",
        coords: [36.61103145563537, 34.30256992777302],
    },
    {
        id: "27",
        brandName: "",
        coords: [36.611281205120044, 34.302333893399044],
    },
    {
        id: "28",
        brandName: "",
        coords: [36.61132426529427, 34.301464857749416],
    },
    {
        id: "29",
        brandName: "",
        coords: [36.615182359537094, 34.32189523235354],
    },
    {
        id: "30",
        brandName: "",
        coords: [36.641339283717905, 34.35319304685514],
    },
    {
        id: "31",
        brandName: "",
        coords: [36.64240397664257, 34.35490272701089],
    },
    {
        id: "32",
        brandName: "",
        coords: [36.64797290000157, 34.36911603907835],
    },
    {
        id: "33",
        brandName: "",
        coords: [36.64774769400913, 34.37041743740587],
    },
    {
        id: "34",
        brandName: "",
        coords: [36.67743381877489, 34.409789047399784],
    },
    {
        id: "35",
        brandName: "",
        coords: [36.67760777454258, 34.40936800676441],
    },
    {
        id: "36",
        brandName: "",
        coords: [36.6775514947784, 34.40917662465743],
    },
    {
        id: "37",
        brandName: "",
        coords: [36.67772033394735, 34.40924041869309],
    },
    {
        id: "38",
        brandName: "",
        coords: [36.67769986861327, 34.4090298983754],
    },
    {
        id: "39",
        brandName: "",
        coords: [36.684278689749135, 34.421080875618806],
    },
    {
        id: "40",
        brandName: "",
        coords: [36.685117941737296, 34.42093652619412],
    },
    {
        id: "41",
        brandName: "",
        coords: [36.69912341647218, 34.45265731403808],
    },
    {
        id: "42",
        brandName: "",
        coords: [36.70184319208593, 34.45312644966833],
    },
    {
        id: "43",
        brandName: "",
        coords: [36.70719566109517, 34.46727269495265],
    },
    {
        id: "44",
        brandName: "",
        coords: [36.707976801045284, 34.46698399610327],
    },
    {
        id: "45",
        brandName: "",
        coords: [36.708092524807036, 34.466442685760676],
    },
    {
        id: "46",
        brandName: "",
        coords: [36.716192755053974, 34.477196717900185],
    },
    {
        id: "47",
        brandName: "",
        coords: [36.716539888695024, 34.47813498916068],
    },
    {
        id: "48",
        brandName: "",
        coords: [36.72862628669242, 34.49960741433409],
    },
    {
        id: "49",
        brandName: "",
        coords: [36.72875397395519, 34.49974397011208],
    },
    {
        id: "50",
        brandName: "",
        coords: [36.73117584431539, 34.50615682462662],
    },
    {
        id: "51",
        brandName: "",
        coords: [36.731837935908764, 34.507766149903716],
    },
    {
        id: "52",
        brandName: "",
        coords: [36.73263759736387, 34.50729408115577],
    },
    {
        id: "53",
        brandName: "",
        coords: [36.73242263542563, 34.50488009324013],
    },
    {
        id: "54",
        brandName: "",
        coords: [36.73248282482899, 34.50433292264592],
    },
    {
        id: "55",
        brandName: "",
        coords: [36.731571380294504, 34.51198258277217],
    },
    {
        id: "56",
        brandName: "",
        coords: [36.73342005379139, 34.51416053631383],
    },
    {
        id: "57",
        brandName: "",
        coords: [36.73435727125776, 34.5135704503789],
    },
    {
        id: "58",
        brandName: "",
        coords: [36.73507092459119, 34.51393523077505],
    },
    {
        id: "59",
        brandName: "",
        coords: [36.73503653181164, 34.51140322567242],
    },
    {
        id: "60",
        brandName: "",
        coords: [36.73561260883718, 34.5120254981129],
    },
    {
        id: "61",
        brandName: "",
        coords: [36.7377185808823, 34.51171249115454],
    },
    {
        id: "62",
        brandName: "",
        coords: [36.737881940719426, 34.516776501962745],
    },
    {
        id: "63",
        brandName: "",
        coords: [36.738010908783, 34.51649755224805],
    },
    {
        id: "64",
        brandName: "",
        coords: [36.7382344529132, 34.516604840599854],
    },
    {
        id: "65",
        brandName: "",
        coords: [36.73885349479935, 34.51598256815937],
    },
    {
        id: "66",
        brandName: "",
        coords: [36.74076217594476, 34.52573507994244],
    },
    {
        id: "67",
        brandName: "",
        coords: [36.74101150464961, 34.52524155352413],
    },
    {
        id: "68",
        brandName: "",
        coords: [36.74119205251647, 34.52584236829425],
    },
    {
        id: "69",
        brandName: "",
        coords: [36.741166259990045, 34.52502697682053],
    },
    {
        id: "70",
        brandName: "",
        coords: [36.74174229101505, 34.52510207866679],
    },
    {
        id: "71",
        brandName: "",
        coords: [36.74427849559772, 34.52385753378584],
    },
    {
        id: "72",
        brandName: "",
        coords: [36.74483730906829, 34.52452272156704],
    },
    {
        id: "73",
        brandName: "",
        coords: [36.746324592785825, 34.52555268974437],
    },
    {
        id: "74",
        brandName: "",
        coords: [36.746780228885406, 34.52707618467839],
    },
    {
        id: "75",
        brandName: "",
        coords: [36.747072522294815, 34.5311102267063],
    },
    {
        id: "76",
        brandName: "",
        coords: [36.747081119142955, 34.53085273466197],
    },
    {
        id: "77",
        brandName: "",
        coords: [36.74736481459088, 34.531571566619064],
    },
    {
        id: "78",
        brandName: "",
        coords: [36.748482392521396, 34.53452199677981],
    },
    {
        id: "79",
        brandName: "",
        coords: [36.74681461640964, 34.537043273047246],
    },
    {
        id: "80",
        brandName: "",
        coords: [36.747115506596074, 34.54151719776759],
    },
    {
        id: "81",
        brandName: "",
        coords: [36.74790641214927, 34.54249352176902],
    },
    {
        id: "82",
        brandName: "",
        coords: [36.748852049393044, 34.54334109974829],
    },
    {
        id: "83",
        brandName: "",
        coords: [36.74934205683534, 34.54300850585769],
    },
    {
        id: "84",
        brandName: "",
        coords: [36.753365157653455, 34.544242322095954],
    },
    {
        id: "85",
        brandName: "",
        coords: [36.75467176035246, 34.54514354425112],
    },
    {
        id: "86",
        brandName: "",
        coords: [36.756476898523196, 34.540798366002974],
    },
    {
        id: "87",
        brandName: "",
        coords: [36.75801553038191, 34.54260081031332],
    },
    {
        id: "88",
        brandName: "",
        coords: [36.75762872639415, 34.546431004472794],
    },
    {
        id: "89",
        brandName: "",
        coords: [36.758058508482364, 34.54591602038412],
    },
    {
        id: "90",
        brandName: "",
        coords: [36.75821322944464, 34.546205698934],
    },
    {
        id: "91",
        brandName: "",
        coords: [36.7622186747921, 34.55157011691985],
    },
    {
        id: "92",
        brandName: "",
        coords: [36.76327587113693, 34.5528683059767],
    },
    {
        id: "93",
        brandName: "",
        coords: [36.76375719147418, 34.550679623599855],
    },
    {
        id: "94",
        brandName: "",
        coords: [36.76386892612041, 34.548716246761806],
    },
    {
        id: "95",
        brandName: "",
        coords: [36.763697026597214, 34.54798668596952],
    },
    {
        id: "96",
        brandName: "",
        coords: [36.76393768582181, 34.54696744662737],
    },
    {
        id: "97",
        brandName: "",
        coords: [36.764444786715664, 34.54800814363988],
    },
    {
        id: "98",
        brandName: "",
        coords: [36.76539022010643, 34.547224938671704],
    },
    {
        id: "99",
        brandName: "",
        coords: [36.77009786470178, 34.56191172203514],
    },
    {
        id: "100",
        brandName: "",
        coords: [36.770905721672534, 34.560710092494915],
    },
    {
        id: "101",
        brandName: "",
        coords: [36.770862750771414, 34.561858077859235],
    },
    {
        id: "102",
        brandName: "",
        coords: [36.7710346342314, 34.562169214079475],
    },
    {
        id: "103",
        brandName: "",
        coords: [36.771326835229004, 34.562898774871755],
    },
    {
        id: "104",
        brandName: "",
        coords: [36.77332064760503, 34.56697573224038],
    },
    {
        id: "105",
        brandName: "",
        coords: [36.77020959010919, 34.567823310219644],
    },
    {
        id: "106",
        brandName: "",
        coords: [36.7790869424867, 34.57526912245381],
    },
    {
        id: "107",
        brandName: "",
        coords: [36.77931036693848, 34.575108189926105],
    },
    {
        id: "108",
        brandName: "",
        coords: [36.779508011102926, 34.57501163040948],
    },
    {
        id: "109",
        brandName: "",
        coords: [36.77938770602003, 34.57465757884852],
    },
    {
        id: "110",
        brandName: "",
        coords: [36.779052569493324, 34.5804189639619],
    },
    {
        id: "111",
        brandName: "",
        coords: [36.78428568892613, 34.58006491240094],
    },
    {
        id: "112",
        brandName: "",
        coords: [36.78505902602417, 34.57981814919179],
    },
    {
        id: "113",
        brandName: "",
        coords: [36.780393107070935, 34.586813350236696],
    },
    {
        id: "114",
        brandName: "",
        coords: [36.781003215752726, 34.58494653291527],
    },
    {
        id: "115",
        brandName: "",
        coords: [36.78247261250227, 34.587918420260294],
    },
    {
        id: "116",
        brandName: "",
        coords: [36.781836736665, 34.59070791740726],
    },
    {
        id: "117",
        brandName: "",
        coords: [36.78342641636581, 34.58747853801789],
    },
    {
        id: "118",
        brandName: "",
        coords: [36.78419116944726, 34.585676093707555],
    },
    {
        id: "119",
        brandName: "",
        coords: [36.78504184087511, 34.58860506571185],
    },
    {
        id: "120",
        brandName: "",
        coords: [36.78248979831159, 34.5952891307756],
    },
    {
        id: "121",
        brandName: "",
        coords: [36.78300536932604, 34.594623942994396],
    },
    {
        id: "122",
        brandName: "",
        coords: [36.78299677650422, 34.59375490734477],
    },
    {
        id: "123",
        brandName: "",
        coords: [36.78330611748272, 34.59228505692502],
    },
    {
        id: "124",
        brandName: "",
        coords: [36.78334908140875, 34.592134853232494],
    },
    {
        id: "125",
        brandName: "",
        coords: [36.78353812239716, 34.59187736118816],
    },
    {
        id: "126",
        brandName: "",
        coords: [36.784483320344314, 34.59581484369944],
    },
    {
        id: "127",
        brandName: "",
        coords: [36.785617542492176, 34.593111177233936],
    },
    {
        id: "128",
        brandName: "",
        coords: [36.78574643031035, 34.592263599254665],
    },
    {
        id: "129",
        brandName: "",
        coords: [36.78598702032429, 34.59282149868405],
    },
    {
        id: "130",
        brandName: "",
        coords: [36.786158869871755, 34.593304296267185],
    },
    {
        id: "131",
        brandName: "",
        coords: [36.786468198086, 34.59305753305803],
    },
    {
        id: "132",
        brandName: "",
        coords: [36.78304833343428, 34.60407604723062],
    },
    {
        id: "133",
        brandName: "",
        coords: [36.783254560753285, 34.604151149076884],
    },
    {
        id: "134",
        brandName: "",
        coords: [36.78368419921877, 34.603872199362186],
    },
    {
        id: "135",
        brandName: "",
        coords: [36.784509098320456, 34.60102905803933],
    },
    {
        id: "136",
        brandName: "",
        coords: [36.78425991098617, 34.598776002651405],
    },
    {
        id: "137",
        brandName: "",
        coords: [36.78582376291082, 34.600235124235965],
    },
    {
        id: "138",
        brandName: "",
        coords: [36.78981058165706, 34.60285296002004],
    },
    {
        id: "139",
        brandName: "",
        coords: [36.782640173581505, 34.60595359406402],
    },
    {
        id: "140",
        brandName: "",
        coords: [36.783886128484255, 34.60532059278836],
    },
    {
        id: "141",
        brandName: "",
        coords: [36.78453917261719, 34.612278242730824],
    },
    {
        id: "142",
        brandName: "",
        coords: [36.78534872024661, 34.61460920066757],
    },
    {
        id: "143",
        brandName: "",
        coords: [36.787559676572805, 34.61371603860229],
    },
    {
        id: "144",
        brandName: "",
        coords: [36.78761747849686, 34.61160492826618],
    },
    {
        id: "145",
        brandName: "",
        coords: [36.78857842909596, 34.6121733041259],
    },
    {
        id: "146",
        brandName: "",
        coords: [36.788679581089546, 34.61224547883825],
    },
    {
        id: "147",
        brandName: "",
        coords: [36.78859287938895, 34.612516134009546],
    },
    {
        id: "148",
        brandName: "",
        coords: [36.78901916180557, 34.61275972366371],
    },
    {
        id: "149",
        brandName: "",
        coords: [36.789358741016606, 34.61161395010522],
    },
    {
        id: "150",
        brandName: "",
        coords: [36.788520627896816, 34.61697292249689],
    },
    {
        id: "151",
        brandName: "",
        coords: [36.78760749259342, 34.619429703779886],
    },
    {
        id: "152",
        brandName: "",
        coords: [36.78831226792379, 34.619733161064296],
    },
    {
        id: "153",
        brandName: "",
        coords: [36.78968534606997, 34.61742688570272],
    },
    {
        id: "154",
        brandName: "",
        coords: [36.78972787464622, 34.61741929927061],
    },
    {
        id: "155",
        brandName: "",
        coords: [36.78989798871514, 34.61736619424584],
    },
    {
        id: "156",
        brandName: "",
        coords: [36.79009847985681, 34.62192564022019],
    },
    {
        id: "157",
        brandName: "",
        coords: [36.790426555064585, 34.6223656532826],
    },
    {
        id: "158",
        brandName: "",
        coords: [36.79046300777875, 34.62197874524497],
    },
    {
        id: "159",
        brandName: "",
        coords: [36.79060881846189, 34.622259443233055],
    },
    {
        id: "160",
        brandName: "",
        coords: [36.790657421961264, 34.62140217640457],
    },
    {
        id: "161",
        brandName: "",
        coords: [36.791058399654695, 34.622873944234],
    },
    {
        id: "162",
        brandName: "",
        coords: [36.79146545062119, 34.62074974324308],
    },
    {
        id: "163",
        brandName: "",
        coords: [36.79162948547392, 34.62263876483858],
    },
    {
        id: "164",
        brandName: "",
        coords: [36.79237675091419, 34.62181184373854],
    },
    {
        id: "165",
        brandName: "",
        coords: [36.79266836471852, 34.62434571206342],
    },
    {
        id: "166",
        brandName: "",
        coords: [36.7931786862051, 34.62153114575045],
    },
    {
        id: "167",
        brandName: "",
        coords: [36.793281965139975, 34.62197874524497],
    },
    {
        id: "168",
        brandName: "",
        coords: [36.79354319947138, 34.62189529449176],
    },
    {
        id: "169",
        brandName: "",
        coords: [36.794758231266336, 34.62490710832737],
    },
    {
        id: "170",
        brandName: "",
        coords: [36.79616156888947, 34.624042255066776],
    },
    {
        id: "171",
        brandName: "",
        coords: [36.796477468185984, 34.62669750630543],
    },
    {
        id: "172",
        brandName: "",
        coords: [36.797637779421024, 34.62421674300531],
    },
    {
        id: "173",
        brandName: "",
        coords: [36.79768637846098, 34.625787134452175],
    },
    {
        id: "174",
        brandName: "",
        coords: [36.79800834632173, 34.62568092440263],
    },
    {
        id: "175",
        brandName: "",
        coords: [36.797862549722254, 34.62766098318345],
    },
    {
        id: "176",
        brandName: "",
        coords: [36.79834853730793, 34.62886722588901],
    },
    {
        id: "177",
        brandName: "",
        coords: [36.79934480229215, 34.628358935281895],
    },
    {
        id: "178",
        brandName: "",
        coords: [36.80004946964807, 34.63327494328946],
    },
    {
        id: "179",
        brandName: "",
        coords: [36.80132514405839, 34.63014174682785],
    },
    {
        id: "180",
        brandName: "",
        coords: [36.80170784223804, 34.62962586944435],
    },
    {
        id: "181",
        brandName: "",
        coords: [36.801823258774036, 34.62974725235811],
    },
    {
        id: "182",
        brandName: "",
        coords: [36.8045567575393, 34.63239491736485],
    },
    {
        id: "183",
        brandName: "",
        coords: [36.80515811415717, 34.63209146008043],
    },
    {
        id: "184",
        brandName: "",
        coords: [36.805328193960044, 34.63234181234007],
    },
    {
        id: "185",
        brandName: "",
        coords: [36.80558938720751, 34.63251630027861],
    },
    {
        id: "186",
        brandName: "",
        coords: [36.80640333254351, 34.63248595455017],
    },
    {
        id: "187",
        brandName: "",
        coords: [36.80555901594544, 34.63342667213186],
    },
    {
        id: "188",
        brandName: "",
        coords: [36.806573409580594, 34.63568742890077],
    },
    {
        id: "189",
        brandName: "",
        coords: [36.80634259065301, 34.63596054045675],
    },
    {
        id: "190",
        brandName: "",
        coords: [36.810800917485764, 34.64059585085226],
    },
    {
        id: "191",
        brandName: "",
        coords: [36.812222182398685, 34.64146070442923],
    },
    {
        id: "192",
        brandName: "",
        coords: [36.815337939923126, 34.639093737610764],
    },
    {
        id: "193",
        brandName: "",
        coords: [36.920604066397175, 34.86169258841016],
    },
    {
        id: "194",
        brandName: "",
        coords: [36.92416624605603, 34.85400862823179],
    },
    {
        id: "195",
        brandName: "",
        coords: [36.92718306397357, 34.85951016185062],
    },
    {
        id: "196",
        brandName: "",
        coords: [36.93045417741328, 34.86301113778987],
    },
    {
        id: "197",
        brandName: "",
        coords: [36.924856853464426, 34.87001308966838],
    },
    {
        id: "198",
        brandName: "",
        coords: [36.927401142569785, 34.872059114567946],
    },
    {
        id: "199",
        brandName: "",
        coords: [36.92467511527921, 34.87537822162724],
    },
    {
        id: "200",
        brandName: "",
        coords: [36.917950497888384, 34.87542368884722],
    },
    {
        id: "201",
        brandName: "",
        coords: [36.921112959394385, 34.877651582626754],
    },
    {
        id: "202",
        brandName: "",
        coords: [36.91780509420581, 34.87546915606721],
    },
    {
        id: "203",
        brandName: "",
        coords: [36.921294706070285, 34.8802432141662],
    },
    {
        id: "204",
        brandName: "",
        coords: [36.90988017426445, 34.88378965855467],
    },
    {
        id: "205",
        brandName: "",
        coords: [36.90195443090423, 34.89174642205297],
    },
    {
        id: "206",
        brandName: "",
        coords: [36.93878291482784, 34.88563652085027],
    },
    {
        id: "207",
        brandName: "",
        coords: [36.92965995700656, 34.88939809186076],
    },
    {
        id: "208",
        brandName: "",
        coords: [36.91950777673973, 34.89640683208842],
    },
    {
        id: "209",
        brandName: "",
        coords: [36.914186980477005, 34.904219325725585],
    },
    {
        id: "210",
        brandName: "",
        coords: [36.91467537821554, 34.91325352618668],
    },
    {
        id: "211",
        brandName: "",
        coords: [36.924699693464355, 34.90463727806008],
    },
    {
        id: "212",
        brandName: "",
        coords: [36.79167561517315, 34.58211596962379],
    },
    {
        id: "213",
        brandName: "",
        coords: [36.792597053624476, 34.579016491408595],
    },
    {
        id: "214",
        brandName: "",
        coords: [36.789692988458214, 34.587030931837845],
    },
    {
        id: "215",
        brandName: "",
        coords: [36.789624251855926, 34.58822183254289],
    },
    {
        id: "216",
        brandName: "",
        coords: [36.79028583909321, 34.587492271750605],
    },
    {
        id: "217",
        brandName: "",
        coords: [36.7686632710968, 34.54301473052144],
    },
    {
        id: "218",
        brandName: "",
        coords: [36.770255475501955, 34.54248368027371],
    },
    {
        id: "219",
        brandName: "",
        coords: [36.776271521881434, 34.537036621638066],
    },
    {
        id: "220",
        brandName: "",
        coords: [36.779552802746366, 34.53878150101972],
    },
    {
        id: "221",
        brandName: "",
        coords: [36.780682989182125, 34.53866011810595],
    },
    {
        id: "222",
        brandName: "",
        coords: [36.78085312333137, 34.54214987687675],
    },
];