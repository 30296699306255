import { MapContainer, TileLayer } from "react-leaflet";
import MarkerList from "../components/MarkerList";
import { useEffect, useState } from "react";
import Preloader from "../components/Preloader";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Header/Header";
import MapInfrastructure from "../components/MapInfrastructure/MapInfrastructure";
import SearchFilters from "../components/SearchFilters/SearchFilters";

const Map = () => {
    const [response, setResponse] = useState(null);
    const [err, setError] = useState(null);
    const [DB, setDB] = useState([]);

    const centerCoords = [36.75279355604626, 34.45478247888124];
    const centerCoordsAlanya = [36.54972595014488, 31.999776803612157];
    const mapZoom = 11;

    const [searchParams, setSearchParams] = useSearchParams();

    const postQuery = searchParams.get("post") || "";
    const price = searchParams.get("price") || "";
    const district = searchParams.get("district") || "";
    const year = searchParams.get("year") || "";
    const roomsQuantity = searchParams.get("roomsquantity") || "";

    const propertyStatus = localStorage.getItem("propertyType");

    useEffect(() => {
        async function fetchData() {
            if (propertyStatus === "primary") {
                try {
                    const json = await fetch("DataBases/DB.json");
                    const res = await json.json();
                    setResponse(res);
                    setDB(res);
                } catch (err) {
                    setError(err);
                }
            }
            if (propertyStatus === "resale") {
                try {
                    const json = await fetch("DataBases/resaleDB.json");
                    const res = await json.json();
                    setResponse(res);
                    setDB(res);
                } catch (err) {
                    setError(err);
                }
            }
            if (propertyStatus === "primaryAlanya") {
                try {
                    const json = await fetch("DataBases/DBAlanya.json");
                    const res = await json.json();
                    setResponse(res);
                    setDB(res);
                } catch (err) {
                    setError(err);
                }
            }
            if (propertyStatus === "resaleAlanya") {
                try {
                    const json = await fetch("DataBases/resaleDBAlanya.json");
                    const res = await json.json();
                    setResponse(res);
                    setDB(res);
                } catch (err) {
                    setError(err);
                }
            }
        }
        fetchData();
    }, [propertyStatus]);

    return (
        <>
            <Header />
            {DB &&
            (propertyStatus === "primary" || propertyStatus === "resale") ? (
                <>
                    <div className='map_container'>
                        <MapContainer
                            style={{ zIndex: 0 }}
                            center={centerCoords}
                            zoom={mapZoom}
                            scrollWheelZoom={true}
                        >
                            <div className='search_container'>
                                <SearchFilters
                                    postQuery={postQuery}
                                    price={price}
                                    district={district}
                                    year={year}
                                    roomsQuantity={roomsQuantity}
                                    setSearchParams={setSearchParams}
                                />
                            </div>
                            <MapInfrastructure />
                            <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
                            <MarkerList
                                filteredCatalog={DB}
                                postQuery={postQuery}
                                year={year}
                                price={price}
                                district={district}
                                roomsQuantity={roomsQuantity}
                            />
                        </MapContainer>
                    </div>
                </>
            ) : DB &&
              (propertyStatus === "primaryAlanya" ||
                  propertyStatus === "resaleAlanya") ? (
                <>
                    <div className='map_container'>
                        <MapContainer
                            style={{ zIndex: 0 }}
                            center={centerCoordsAlanya}
                            zoom={mapZoom}
                            scrollWheelZoom={true}
                        >
                            <div className='search_container'>
                                <SearchFilters
                                    postQuery={postQuery}
                                    price={price}
                                    district={district}
                                    year={year}
                                    roomsQuantity={roomsQuantity}
                                    setSearchParams={setSearchParams}
                                />
                            </div>
                            <MapInfrastructure />
                            <TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
                            <MarkerList
                                filteredCatalog={DB}
                                postQuery={postQuery}
                                year={year}
                                price={price}
                                district={district}
                                roomsQuantity={roomsQuantity}
                            />
                        </MapContainer>
                    </div>
                </>
            ) : err ? (
                <div>Sorry, we will fix it soon ...</div>
            ) : (
                <Preloader />
            )}
        </>
    );
};

export default Map;
