import { Navigate } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
    const user = localStorage.getItem("user1");
    if (user) {
        return children;
    } else {
        return <Navigate to='login' />;
    }
};

export default ProtectedRoute;
// Данный код представляет собой React компонент "ProtectedRoute", который проверяет, есть ли объект пользователя в локальном хранилище браузера. Если пользователь прошел аутентификацию, то компонент рендерит полученные от родительского компонента дочерние элементы. Если же пользователь не авторизован, то компонент перенаправляет на страницу авторизации с помощью компонента "Navigate" из библиотеки "react-router-dom".
