export const agents = [
    { email: "rafaelhovsepyan92@gmail.com", avatarUrl: "avatars/agent.png" },
    { email: "acrobatca@mail.ru", avatarUrl: "avatars/agent.png" },
    { email: "temp@mail.ru", avatarUrl: "avatars/agent.png" },
    { email: "nermet07@gmail.com", avatarUrl: "avatars/agent.png" },
    { email: "dimass82@mail.ru", avatarUrl: "avatars/dizzy.png" },
    { email: "ruslan_kenesbaev@mail.ru", avatarUrl: "avatars/1.png" },
    { email: "7787418@gmail.com", avatarUrl: "avatars/2.png" },

    { email: "katrin77_09@mail.ru", avatarUrl: "avatars/9.png" },
    { email: "gulsina-a2013@yandex.ru", avatarUrl: "avatars/agent.png" },
    { email: "kamilarafa@mail.ru", avatarUrl: "avatars/kamila.png" },
    { email: "danilu6a@icloud.com", avatarUrl: "avatars/10.png" },
    { email: "welln3mb@gmail.com", avatarUrl: "avatars/agent.png" },
];
