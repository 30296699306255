import Map from "./pages/Map";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotFound from "./pages/NotFound";
import House from "./pages/House";
import { useFetch } from "./Hooks/useFetch";
import PanoramaPage from "./pages/PanoramaPage";
import VideoPage from "./pages/VideoPage";
import PlanPage from "./pages/PlanPage";
import FloorPage from "./pages/FloorPage";
import InteriorPage from "./pages/InteriorPage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import PDFPage from "./pages/PDFPage";
import FavoritePage from "./pages/FavoritePage";
import Footer from "./components/Footer/Footer";
import InformationPage from "./pages/InformationPage/InformationPage";
import InfrastructurePage from "./pages/InfrastructurePage/InfrastructurePage";
// import { Suspense, lazy } from "react";
// import Preloader from "./components/Preloader";

AOS.init({
    duration: 1500,
});

// const House = lazy(() => import("./pages/House"));
// const PanoramaPage = lazy(() => import("./pages/PanoramaPage"));
// const VideoPage = lazy(() => import("./pages/VideoPage"));
// const PlanPage = lazy(() => import("./pages/PlanPage"));
// const FloorPage = lazy(() => import("./pages/FloorPage"));
// const InteriorPage = lazy(() => import("./pages/InteriorPage"));
// const LoginPage = lazy(() => import("./pages/LoginPage"));
// const RegisterPage = lazy(() => import("./pages/RegisterPage"));
// const PDFPage = lazy(() => import("./pages/PDFPage"));
// const FavoritePage = lazy(() => import("./pages/FavoritePage"));
// const InformationPage = lazy(() =>
//     import("./pages/InformationPage/InformationPage"),
// );
// const InfrastructurePage = lazy(() =>
//     import("./pages/InfrastructurePage/InfrastructurePage"),
// );
// const Map = lazy(() => import("./pages/Map"));
// const NotFound = lazy(() => import("./pages/NotFound"));

function App() {
    const res = useFetch();
    const db = res.response;
    const error = res.error;
    return (
        <div className='App'>
            <>
                <BrowserRouter>
                    <main className='content'>
                        <Routes>
                            <Route
                                path='/login'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <PublicRoute>
                                            <LoginPage />
                                        </PublicRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/register'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <PublicRoute>
                                            <RegisterPage />
                                        </PublicRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                exact
                                path='/'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <Map />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                exact
                                path='/favorites'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <FavoritePage />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/houses/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <House db={db} error={error} />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/plan/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <PlanPage db={db} error={error} />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/interior/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <InteriorPage
                                                db={db}
                                                error={error}
                                            />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/floor/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <FloorPage db={db} error={error} />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/info/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <InformationPage
                                                db={db}
                                                error={error}
                                            />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />

                            <Route
                                path='/infrastructure/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <InfrastructurePage
                                                db={db}
                                                error={error}
                                            />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/video/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <VideoPage db={db} error={error} />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/panorama/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <ProtectedRoute>
                                            <PanoramaPage
                                                db={db}
                                                error={error}
                                            />
                                        </ProtectedRoute>
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='/pdf/:id'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <PDFPage db={db} error={error} />
                                    // </Suspense>
                                }
                            />
                            <Route
                                path='*'
                                element={
                                    // <Suspense fallback={<Preloader />}>
                                        <NotFound />
                                    // </Suspense>
                                }
                            />
                        </Routes>
                    </main>
                    <Footer />
                </BrowserRouter>
            </>
        </div>
    );
}

export default App;
