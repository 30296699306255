import React from "react";

const NotFound = () => (
    <div className='not-found-container'>
        <h1>Page not found</h1>
        <button className='btn'>
            <a href='/'>
                Link to main page
            </a>
        </button>
    </div>
);

export default NotFound;
