import "./imageSlider.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { useState } from "react";

const rightArrowStyles = {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    right: "45px",
    fontSize: "45px",
    color: "black",
    zIndex: 1,
    cursor: "pointer",
};

const leftArrowStyles = {
    position: "absolute",
    top: "50%",
    transform: "translate(0, -50%)",
    left: "45px",
    fontSize: "45px",
    color: "black",
    zIndex: 1,
    cursor: "pointer",
};

// const sliderStyles = {
//     position: "relative",
//     height: "100%",
// };

// const dotsContainerStyles = {
//     display: "flex",
//     justifyContent: "center",
//     height: "30px",
//     width: "auto",
// };

// const dotStyle = {
//     margin: "0 3px",
//     cursor: "pointer",
//     fontSize: "20px",
// };

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div style={rightArrowStyles} onClick={onClick}>
            ❱
        </div>
    );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div style={leftArrowStyles} onClick={onClick}>
            ❰
        </div>
    );
}

const ImageSlider = (props) => {
    var settings = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        // autoplay: true,
        // pauseOnHover: true,
        // autoplaySpeed: 6000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const { slides } = props;

    // const [currentIndex, setCurrentIndex] = useState(0);

    // const goToPrevious = () => {
    //     const isFirstSlide = currentIndex === 0;
    //     const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    //     setCurrentIndex(newIndex);
    // };
    // const goToNext = () => {
    //     const isLastSlide = currentIndex === slides.length - 1;
    //     const newIndex = isLastSlide ? 0 : currentIndex + 1;
    //     setCurrentIndex(newIndex);
    // };
    // const goToSlide = (slideIndex) => {
    //     setCurrentIndex(slideIndex);
    // };
    // const slideStylesWidthBackground = {
    //     ...slideStyles,
    //     backgroundImage: `url(${slides[currentIndex]})`,
    // };

    return (
        <div>
            <Slider {...settings}>
                {slides.map((slide, slideIndex) => (
                    <div
                        // style={dotStyle}
                        key={slideIndex}
                        // onClick={() => goToSlide(slideIndex)}
                    >
                        <img
                            className='slideStyles'
                            alt='preview_photo'
                            // style={slideStyles}
                            src={slide}
                        />
                    </div>
                ))}
            </Slider>
        </div>
        // <div style={sliderStyles}>
        //     <div>
        //         <div onClick={goToPrevious} style={leftArrowStyles}>
        //             ❰
        //         </div>
        //         <div onClick={goToNext} style={rightArrowStyles}>
        //             ❱
        //         </div>
        //     </div>
        //     <div className='mainSlide' style={slideStylesWidthBackground}></div>
        //     <div style={dotsContainerStyles}>
        // {slides.map((slide, slideIndex) => (
        //     <div
        //         style={dotStyle}
        //         key={slideIndex}
        //         onClick={() => goToSlide(slideIndex)}
        //     >
        //         <img
        //             alt='preview_photo'
        //             style={{
        //                 height: "30px",
        //                 width: "45px",
        //                 marginTop: "5px",
        //             }}
        //             src={slide}
        //         />
        //         {/* ● */}
        //     </div>
        // ))}
        //     </div>
        // </div>
    );
};

export default ImageSlider;
