import { useParams } from "react-router-dom";
import ButtonsBlock from "../components/ButtonsBlock/ButtonsBlock";
import MenuButtonsBlock from "../components/MenuButtonsBlock/MenuButtonsBlock";
import Preloader from "../components/Preloader";
import VideoPlayer from "../components/VideoPlayer";

const VideoPage = (props) => {
    const { db, error } = props;
    const { id } = useParams();

    const containerStyles = {
        maxWidth: "800px",
        height: "550px",
        margin: "0 auto",
    };

    const getFiltered = (id) => {
        const filteredDB = db.find((i) => i.id === id); // заменить на find

        const video = filteredDB.video;
        const name = filteredDB.name;
        const itemId = filteredDB.id;
        const streetView = filteredDB.streetview;
        const slides = filteredDB.houseurls;
        const houseId = filteredDB.id;
        const linkToPrice = filteredDB.price;
        const dateOfPutting = filteredDB.dateofputting;
        const roomsQuantity = filteredDB.roomsQuantity;
        const district = filteredDB.district;
        const minPrice = filteredDB.minPrice;
        const distance = filteredDB.distance;
        const pdf = filteredDB.pdf;

        const defaultImage =
            "https://i.ibb.co/QcztxHJ/600px-No-image-available-svg.png";

        return (
            <>
                <MenuButtonsBlock houseId={houseId} />
                <div className='container '>
                    <div className='residental_name' key={itemId}>
                        {name}
                    </div>
                    <div data-aos='zoom-in'>
                        <div style={containerStyles}>
                            {video ? (
                                <VideoPlayer video={video} houseId={houseId} />
                            ) : (
                                <img
                                    alt='img'
                                    style={{
                                        height: "300px",
                                        display: "block",
                                        margin: "0 auto",
                                    }}
                                    src={defaultImage}
                                />
                            )}
                        </div>
                    </div>
                    <ButtonsBlock
                        streetView={streetView}
                        linkToPrice={linkToPrice}
                        distance={distance}
                        minPrice={minPrice}
                        district={district}
                        roomsQuantity={roomsQuantity}
                        dateOfPutting={dateOfPutting}
                        name={name}
                        itemId={itemId}
                        slides={slides}
                        pdf={pdf}
                    />
                </div>
            </>
        );
    };

    return (
        <>
            {db ? (
                getFiltered(id)
            ) : error ? (
                <div>Sorry, we will fix it soon ...</div>
            ) : (
                <Preloader />
            )}
        </>
    );
};
export default VideoPage;
