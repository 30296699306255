const PanoramaPlayer = (props) => {
    const { panorama, houseId } = props;
    return (
        <div
            // style={{
            //     maxWidth: "800px",
            //     height: "550px",
            //     margin: "0 auto",
            // }}
        >
            <iframe
                // width='800'
                // height='550'
                title={houseId}
                frameBorder='0'
                allow='vr,gyroscope,accelerometer,fullscreen'
                scrolling='no'
                allowFullScreen={true}
                src={panorama}
            ></iframe>
        </div>
    );
};

export default PanoramaPlayer;
